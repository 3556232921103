import queryString from "query-string";
import React, {
  FunctionComponent, useEffect, useState
} from "react";
import { Tab } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import CompanyForm, {
  CompanyFormInput
} from "../Components/provider-forms/CompanyInfo";
import PaymentInfoForm, {
  PaymentInfoFormInput
} from "../Components/provider-forms/PaymentInfo";
import ProjectInfoForm from "../Components/provider-forms/ProjectInfo";
import TourInfoForm from "../Components/provider-forms/TourInfo";
import { RootState } from "../store";
import { createCompleteDraft, deleteDraft, fetchDrafts, upsertDraft } from "../store/Drafts";
import { Project } from "../store/Projects";
import { Tours } from "../store/Tours";


type providerApplicationForm = CompanyFormInput &
  Project &
  Tours &
  PaymentInfoFormInput & {
    type: "project" | "tour";
    businessCategory: "company" | "freelancer";
  };
type stepType = "Company Info" | "Project Info" | "Tour Info" | "Payment Info";
export const ProviderApplicationForm: FunctionComponent = ({ }) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<any>();
  const [startOver, setStartOver] = useState<boolean>(false);
  const [steps, setSteps] = useState<stepType[]>([]);
  const [selectedTab, setSelectedTab] = useState<stepType>("Company Info")
  const isMobileView = useMediaQuery({ maxWidth: 700 });

  const onSaveData = async (data: any, shoudSaveDraft: boolean = false) => {
    const _formData = { ...formData, ...data }
    setFormData(_formData);
    if (shoudSaveDraft) {
      try {
        const savedFormData = await dispatch(upsertDraft({ ..._formData, lang: localStorage.getItem('language') ?? 'en' }));
        setFormData(savedFormData);
        toast.success("Saved Draft");
      } catch (error: any) {
        toast.error(error.response.data.err.map((e: any) => e.message).join('\n'));
      }
    }
  };

  const createAccount = async (data: any) => {
    try {
      onSaveData(data, false)
      const savedFormData = await dispatch(createCompleteDraft({ ...formData, ...data, lang: localStorage.getItem('language') ?? 'en' }));
      setFormData(savedFormData);
      toast.success("Application Submitted Successfully");
      history.push("/");
    }
    catch (error: any) {
      toast.error(error.response.data.err);
    }
  };
  const Language = useSelector(
    (rootState: RootState) => rootState.language.language?.content
  );
  useEffect(() => {
    (async () => {
      const { businessCategory, eventType } = queryString.parse(
        location.search
      );
      const drafts = await dispatch(fetchDrafts());
      if (drafts?.length > 0) {
        const draft = (drafts as any)[0];
        setFormData(draft);
      } else {
        setFormData({
          type: eventType || "project",
          businessCategory: businessCategory || "company",
        });
      }
      let steps: stepType[] = [];
      if (businessCategory === "company") {
        steps = [...steps, 'Company Info'];
      }
      steps = [
        ...steps,
        eventType === "project" ? 'Project Info' : 'Tour Info',
        'Payment Info',
      ];
      setSteps(steps);
      setSelectedTab(steps[0]);
    })();
  }, [Language]);

  const onStartOver = () => {
    setStartOver(true);
  }
  const handleConfirmStartOver = async () => {
    if (formData.id) {
      const savedFormData = await dispatch(deleteDraft(formData.id));
    }
    toast.success("You can now start over your application to become a provider.");
    history.push('/')
    setFormData(null);
  }

  if (!formData) return <></>;
  return (
    <>
      {startOver && (
        <SweetAlert
          title="Are You sure you want to start over your application?"
          danger
          showCancel
          confirmBtnBsStyle="danger"
          onConfirm={handleConfirmStartOver}
          onCancel={() => setStartOver(false)}
        />
      )}
      <div className="container">
        <div className="row justify-content-center paddresup">
          <h1 className="restxt com-font text-capitalize">
            {Language?.provider_application}
          </h1>
        </div>
        <div className="row m-0">
          <Tab.Container
            activeKey={selectedTab}
            onSelect={(key) => setSelectedTab(key as any)}
          >
            {/* Nav tabs */}
            <div className="col-lg-12 col-md-12 p-0">
              <ul
                // className="nav nav-tabs process-model sa-process-model more-icon-preocess"
                className="nav nav-tabs process-model more-icon-preocess m-0 w-100"
                role="tablist"
              >
                {steps.map((s: any, i) => (
                  <li
                    role="presentation"
                    key={i}
                    className={steps.indexOf(selectedTab) >= i ? "active" : ""}
                  >
                    <a aria-controls="companyInfo" role="tab" data-toggle="tab">
                      <img
                        src={s === "Company Info" ?
                        "/images/company_info.svg" : s === "Project Info" ? "/images/community_holidays.svg" :
                          s === "Tour Info" ? "/images/adventure_tours.svg" : "/images/secured_payment_info.svg"}  
                        className="img-fluid checkout-icon"
                        alt=""
                      />
                      {!isMobileView && <p className="position-absolute text-dark m-0 hidden">{s === "Company Info" ?
                        Language?.general?.company_info : s === "Project Info" ? Language?.general?.project_info :
                          s === "Tour Info" ? Language?.general?.tour_info : Language?.general?.payment_info}</p>}
                    </a>
                  </li>
                  
                ))}
              </ul>
            </div>
            {/* end design process steps*/}
            {/* Tab panes */}
            <div className="tab-content col-lg-12">
              <Tab.Content className="tab-content tabconpadd col-lg-12">
                {steps.map((s, i) => {

                  if (s === "Company Info") {
                    return (
                      <Tab.Pane eventKey={s}>
                        <CompanyForm
                          initialData={formData}
                          onSave={onSaveData}
                          onStartOver={onStartOver}
                          onNext={() => {
                            setSelectedTab(steps[1]);
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                        />
                      </Tab.Pane>
                    );
                  } else if (s === "Project Info") {
                    return (
                      <Tab.Pane eventKey={s}>
                        <ProjectInfoForm
                          initialData={formData}
                          onSave={onSaveData}
                          onStartOver={onStartOver}
                          onNext={() => {
                            setSelectedTab("Payment Info");
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          onPrev={() => {
                            setSelectedTab(steps[i - 1]);
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          hidePrev={i === 0}
                        />
                      </Tab.Pane>
                    );
                  } else if (s === "Tour Info") {
                    return (
                      <Tab.Pane eventKey={s}>
                        <TourInfoForm
                          initialData={formData}
                          onSave={onSaveData}
                          onStartOver={onStartOver}
                          onNext={() => {
                            setSelectedTab("Payment Info");
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          onPrev={() => {
                            setSelectedTab(steps[i - 1]);
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          hidePrev={i === 0}
                        />
                      </Tab.Pane>
                    );
                  } else if (s === "Payment Info") {
                    return (
                      <Tab.Pane eventKey={s}>
                        <PaymentInfoForm
                          initialData={formData}
                          onStartOver={onStartOver}
                          onSave={onSaveData}
                          onPrev={() => {
                            setSelectedTab(steps[i - 1])
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          createAccount={createAccount}
                        />
                      </Tab.Pane>
                    );
                  }
                })}
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};
