export interface ProjectPicture {
    imageURL: string;
    public_id: string;
    imageName: string;
}

export interface ExtraAccommodation {
    category: string;
    extraPrice: string;
    description: string;
}

export interface CloudinaryImg {
    imageURL: string;
    public_id: string;
    imageName: string;
}
export interface Voucher {
    createdAt: Date;
    discountValue: number;
    id: number;
    updatedAt: Date;
    voucher: string;
    isPercentageDiscount: boolean;
}

export interface EventDetailsFile {
    url: string;
}

export interface Project {
    eventType: "project"
    projectPictures: CloudinaryImg[];
    qualifications: string[];
    extraAccommodation: ExtraAccommodation[];
    extraAccommodation_de: ExtraAccommodation[];
    extraAccommodation_es: ExtraAccommodation[];
    accommodationPicture: CloudinaryImg[];
    transportationPicture: CloudinaryImg[];
    available: boolean;
    status: string;
    removed: string;
    feedback: any[];
    id: number,
    rating?: number,
    // _id: string;
    customId: string;
    projectName: string;
    projectName_de: string;
    projectName_es: string;
    contactEmail: string;
    projectOwnerPicture: CloudinaryImg[];
    projectOwnerName: string;
    ownerDescription: string;
    projectDescription: string;
    program: any[];
    localSupervisorName: string;
    localSupervisorPhone: string;
    location: string;
    // startDate: Date | string;
    // endDate: Date | string;
    startDate: string;
    endDate: string;
    capacity: number;
    minCapacity: number;
    category: string;
    accommodationDescription: string;
    numberOfReservations: number;
    price: number;
    pricePerDay: number;
    pricePerMonth: number;
    additionalPrice: string;
    additionalPrice_de: string;
    additionalPrice_es: string;
    foodDescription: string;
    transportation: string;
    additionalInfo: string;
    createdBy: string;
    typicalDay: string;
    // __v: number;
    noticePeriod?: number;
    continent: string;
    levelOfDifficulty: string;
    nearAirport: string;
    consumerPricePerDay: number;
    consumerPrice: number;
    includedEventsDates: any[];
    natureOfEvent: string;
    spokenLanguages: string;
    natureOfEventdescription: string;
    choiceOfFood: string;
    eventCity: string;
    duration?: string;
    extraChoiceOfFood: ExtraChoiceOfFood[];
    extraChoiceOfFood_de: ExtraChoiceOfFood[];
    extraChoiceOfFood_es: ExtraChoiceOfFood[];
    foodPrice: number;
    vouchers: Voucher[];
    companyInfo_de: string;
    ownerDescription_de: string;
    projectDescription_de: string;
    accommodationDescription_de: string;
    foodDescription_de: string;
    transportation_de: string;
    additionalInfo_de: string;
    typicalDay_de: string;
    tourDescription_de: string;
    natureOfEventdescription_de: string;
    spokenLanguages_de: string
    companyInfo_es: string;
    ownerDescription_es: string;
    projectDescription_es: string;
    accommodationDescription_es: string;
    foodDescription_es: string;
    transportation_es: string;
    additionalInfo_es: string;
    typicalDay_es: string;
    tourDescription_es: string;
    natureOfEventdescription_es: string;
    spokenLanguages_es: string
    airportCost: number;
    sdg: string;
    sdg_es: string;
    sdg_de: string;
    natureOfEventHeading?: string;
    event_day_details: EventDetailsFile;
    lang: string;
}
export interface ExtraChoiceOfFood {
    category: string;
    description: string;
}

export interface ProjectsState {
    UpcomingProjects: Project[],
    selectedProject: Project | undefined,
    AllProjects: Project[]
}
export const SET_UPCOMING_PROJECTS = "SET_UPCOMING_PROJECTS"
export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT"
export const SET_ALL_PROJECTS = "SET_ALL_PROJECTS"

export interface SetUpcomingProjectsAction {
    type: typeof SET_UPCOMING_PROJECTS,
    payload: Project[]
}

export interface SetSelectedProjectsAction {
    type: typeof SET_SELECTED_PROJECT,
    payload: Project
}

export interface SetAllProjectsAction {
    type: typeof SET_ALL_PROJECTS,
    payload: Project[]
}

export type ProjectsActionTypes = SetUpcomingProjectsAction | SetSelectedProjectsAction | SetAllProjectsAction