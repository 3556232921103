import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import dayjs from "dayjs";
import cloneDeep from "lodash/cloneDeep";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Col, Form, Row, Tab } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import BookingDetails from "../Components/BookingDetailsCard";
import FileUpload from "../Components/FileUpload";
import Insurance from "../Components/Insurance";
import { Spinner } from "../Components/Spinner";
import TraverlersForm from "../Components/TravelersForm";
import { RootState } from "../store";
import { CartItem, createReservation } from "../store/Cart";
import { CloudinaryImg } from "../store/Projects";
import {
  CheckoutDetails,
  ReservationsInfo,
  TravelersInfo,
} from "../types/CheckoutDetails";
import { useMediaQuery } from "react-responsive";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

export const Checkout: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [insuranceCheck, setinsuranceCheck] = useState("");
  const [legalDocument, setlegalDocument] = useState(false);
  const [hospitalityCheck, setHospitalityCheck] = useState(false);
  const [selectedTab, setSelectedTab] = useState("event-0");
  const [recommenderId, setRecommenderId] = useState("");
  const [cardToken, setCardToken] = useState("");
  const [cardOwnerName, setCardOwnerName] = useState("");
  const [cardOwnerError, setCardOwnerError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [checkoutDetails, setCheckoutDetails] = useState<
    CheckoutDetails | undefined
  >(undefined);
  const [steps, setSteps] = useState([
    "insurance",
    "payment-details",
    "payment",
  ]);
  const [passportIdCard, setPassportIdCard] = useState<CloudinaryImg[]>([]);
  const isMobileView = useMediaQuery({ maxWidth: 700 });
  const userDetails = useSelector(
    (rootState: RootState) => rootState.authReducer.UserDetails
  );
  const documents = useSelector((rootState: RootState) => rootState.documents.documentContent);
  const cart = useSelector((rootState: RootState) => rootState.cart.Cart);
  const Language = useSelector((state: RootState) => state.language.language?.content)

  const getEventType = (cartItem: CartItem) => {
    return cartItem.projectId ? "project" : "tour";
  };
  const totalPrice =
    cart?.items.reduce((acc, { price }) => acc + price, 0) || 0;

  useEffect(() => {
    if (cart) {
      setSteps([...cart.items.map((_, i) => `event-${i}`), ...steps]);
      const checkoutDetails: CheckoutDetails = {
        userId: cart.userID,
        userName: userDetails?.firstName + " " + userDetails?.lastName,
        userEmail: userDetails?.email!,
        recommenderId: recommenderId,
        insurance: insuranceCheck === 'has',
        totalPrice: cart.items.reduce((acc, { price }) => acc + price, 0),
        reservationsInfo: cart.items.map((cart) => {
          const noOfParticipants = parseInt(cart.participants);
          // const newStartDate = new Date(cart?.startDate).toLocaleDateString();
          // const newEndDate = new Date(cart?.endDate).toLocaleDateString();
          // console.log('new',  new Date(newStartDate), typeof newEndDate)
          const reserveationInfo: ReservationsInfo = {
            eventId: cart.projectId! || cart.tourId!,
            eventType: getEventType(cart),
            eventName: cart.projectName! || cart.tourName!,
            eventOwner: cart.projectOwnerId! || cart.tourOwnerId!,
            // startDate: new Date(newStartDate),
            // endDate: new Date(newEndDate),
            startDate: dayjs(cart.startDate).format('YYYY-MM-DD'),
            endDate: dayjs(cart.endDate).format('YYYY-MM-DD'),
            numberOfNights: cart.numberOfNights,
            eventEmergencyContact: cart.localSupervisorPhone,
            eventContactMail: cart.contactEmail,
            eventSupervisorName: cart.localSupervisorName,
            accommodation: cart.accommodation,
            nearAirport: cart.nearAirport,
            spokenLanguages: cart.spokenLanguages,
            natureOfEvent: cart.natureOfEvent,
            natureOfEventdescription: cart.natureOfEventdescription,
            levelOfDifficulty: cart.levelOfDifficulty,
            voucherCode: cart.voucherCode,
            discountApplied: cart.discountApplied,
            recommenderId: recommenderId,
            actualPrice: cart.actualPrice,
            toPaid: cart.price,
            travelersInfo: [
              ...new Array(noOfParticipants || 0).fill({
                gender: "",
                firstName: "",
                lastName: "",
                country: "",
                zip: "",
                address1: "",
                address2: "",
                email: "",
                phoneNumber: "",
              }),
            ],
          };
          return reserveationInfo;
        }),
        passportIdCard: [],
      };
      setCheckoutDetails(checkoutDetails);
    }
  }, [cart, recommenderId]);

  const setInsurance = (status: string) => {
    setinsuranceCheck(status);
    const copyOfCheckoutDetails = { ...checkoutDetails! };
    copyOfCheckoutDetails.insurance = status === 'has';
    setCheckoutDetails(copyOfCheckoutDetails);
  };

  useEffect(() => {
    if (checkoutDetails) {
      const checkout = {
        ...checkoutDetails,
        passportIdCard,
      };
      setCheckoutDetails(checkout);
    }
  }, [passportIdCard]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [selectedTab])

  const [showCopyInfo, setShowCopyInfo] = useState(false);
  const setTravlersData = (travelersData: TravelersInfo[], index: number) => {
    const copyOfCheckoutDetails = { ...checkoutDetails! };
    copyOfCheckoutDetails.reservationsInfo[index].travelersInfo = travelersData;
    setCheckoutDetails(copyOfCheckoutDetails);
    if (index + 1 >= checkoutDetails?.reservationsInfo.length!) {
      setSelectedTab("insurance");
    } else {
      setShowCopyInfo(true);
    }
  };

  const copyPreviousDetails = () => {
    const copyOfCheckoutDetails = cloneDeep(checkoutDetails!);
    const currentIndex = parseInt(selectedTab.split("-")[1]);
    const prevRevervationInfo =
      copyOfCheckoutDetails?.reservationsInfo[currentIndex];
    const nextRevervationInfo =
      copyOfCheckoutDetails?.reservationsInfo[currentIndex + 1];

    nextRevervationInfo.travelersInfo = nextRevervationInfo.travelersInfo.map(
      (info, i) => {
        if (i < prevRevervationInfo.travelersInfo.length) {
          return {
            ...prevRevervationInfo.travelersInfo[i],
          };
        }
        return info;
      }
    );
    setCheckoutDetails(copyOfCheckoutDetails);
    setShowCopyInfo(false);
    setSelectedTab(`event-${currentIndex + 1}`);
  };
  const cancelCopy = () => {
    const currentIndex = parseInt(selectedTab.split("-")[1]);
    setShowCopyInfo(false);
    setSelectedTab(`event-${currentIndex + 1}`);
  };

  const saveReservation = async () => {
    try {
      setLoading(true);
      await setTimeout(async () => {
        await dispatch(createReservation(checkoutDetails!, cardToken, history));
        setLoading(false);
      }, 1);
      console.log("checkout---------details", checkoutDetails);
    } catch (e) {
      console.error("payjment error", e);
      setLoading(false);
    }
  };

  const tabTitle = useMemo(() => {
    if (selectedTab.startsWith("event")) {
      const index = parseInt(selectedTab.split("-")[1]);
      return checkoutDetails?.reservationsInfo[index]?.eventName;
    } else if (selectedTab === "insurance") {
      return "Insurance";
    } else if (selectedTab === "payment-details") {
      return Language?.general.payment_details;
    } else if (selectedTab === "payment") {
      return "Payment";
    }
  }, [selectedTab, checkoutDetails]);

  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (stripe: any, elements: any) => {
    if (!stripe || !elements) {
      return;
    }
    if(cardOwnerError === true || cardOwnerName === "") {
      setCardOwnerError(true);
      return;
    }
    setProcessing(true);
    try {
      const cardNumberElement = await elements.getElement(CardNumberElement);
      const response = await stripe.createToken(cardNumberElement);
      if (response.token) {
        toast.success("Credit card validated successfully");
        setProcessing(false);
        setCardToken(response.token.id);
        return;
      } else if (response.error) {
        setProcessing(false);
        setCardToken("");
        toast.warn(response?.error?.message);
        return;
      }
      return;
    } catch (e) {
      console.log("stripe:error: ", e);
    }
  };

  if (!cart) return null;
  return (
    <>
      {loading &&
        <SweetAlert
          children={<><Spinner /></>}
          title="Your reservation is being processed, please wait a moment."
          showCancel={false}
          showConfirm={false}
          onConfirm={() => { }}
        />
      }
      {showCopyInfo && (
        <SweetAlert
          title="Do you want to copy Details"
          showCancel
          showConfirm
          cancelBtnCssClass="btnSaveandStart"
          confirmBtnCssClass="btnviewproj"
          confirmBtnText="Yes"
          cancelBtnText="No"
          onCancel={cancelCopy}
          onConfirm={copyPreviousDetails}
        />
      )}
      <div className="container">
        <div className="row justify-content-center paddresup m-0">
          <div className="restxt com-font">{tabTitle}</div>
        </div>
        <div className="row m-0">
          <Tab.Container
            activeKey={selectedTab}
            onSelect={(key) => setSelectedTab(key!)}
          >
            {/* Nav tabs */}
            <div className="col-lg-12 col-md-12 p-0">
              <ul
                className="nav nav-tabs process-model more-icon-preocess m-0 w-100"
                role="tablist"
              >
                {checkoutDetails?.reservationsInfo.map((item, i) => (
                  <li
                    role="presentation"
                    key={i}
                    className={steps.indexOf(selectedTab) >= i ? "active" : ""}
                  >
                    <a
                      href="#discover"
                      aria-controls="discover"
                      role="tab"
                      data-toggle="tab"
                    >
                      <img
                        src={item.eventType === 'tour' ? '/images/tours_icon.svg' : '/images/community_holidays.svg'}
                        className="img-fluid checkout-icon"
                        alt=""
                      />
                    </a>
                    {!isMobileView && <p className="position-absolute text-dark m-0 hidden">{Language?.general?.checkout_description_options[0]}</p>}
                  </li>
                ))}
                <li
                  role="presentation"
                  className={
                    steps.indexOf(selectedTab) >= steps.indexOf("insurance")
                      ? "active"
                      : ""
                  }
                >
                  <a
                    href="#strategy"
                    aria-controls="strategy"
                    role="tab"
                    data-toggle="tab"
                  >
                    <img
                      src="/images/insurance.svg"
                      className="img-fluid checkout-icon"
                      alt=""
                    />
                  </a>
                  {!isMobileView && <p className="position-absolute text-dark m-0 hidden">{Language?.general?.checkout_description_options[1]}</p>}
                </li>
                <li
                  role="presentation"
                  className={
                    steps.indexOf(selectedTab) >=
                      steps.indexOf("payment-details")
                      ? "active"
                      : ""
                  }
                >
                  <a
                    href="#details"
                    aria-controls="details"
                    role="tab"
                    data-toggle="tab"
                  >
                    <img
                      src="/images/security-payment.png"
                      className="img-fluid checkout-icon"
                      alt=""
                    />
                  </a>
                  {!isMobileView && <p className="position-absolute text-dark m-0 hidden">{Language?.general?.checkout_description_options[2]}</p>}
                </li>
                <li
                  role="presentation"
                  className={
                    steps.indexOf(selectedTab) >= steps.indexOf("payment")
                      ? "active"
                      : ""
                  }
                >
                  <a
                    href="#content"
                    aria-controls="content"
                    role="tab"
                    data-toggle="tab"
                  >
                    <img
                      src="/images/credit-card.svg"
                      className="img-fluid checkout-icon"
                      alt=""
                    />
                  </a>
                  {!isMobileView && <p className="position-absolute text-dark m-0 hidden">{Language?.general?.checkout_description_options[3]}</p>}
                </li>
              </ul>
             
            </div>
            {/* end design process steps*/}
            {/* Tab panes */}
            <Tab.Content className="tab-content tabconpadd col-lg-12">
              {checkoutDetails?.reservationsInfo.map((reservationsInfo, i) => (
                <Tab.Pane eventKey={`event-${i}`}>
                  <TraverlersForm
                    reservationsInfo={reservationsInfo}
                    noOfParticipants={reservationsInfo.travelersInfo.length}
                    onSubmit={(data) => setTravlersData(data, i)}
                  />
                </Tab.Pane>
              ))}
              <Tab.Pane eventKey="insurance">
                <h4 className="com-font fw700 text-red">
                  {Language?.insurance?.Insurance}
                </h4>
                <h6 className="com-font fw500 mb-3">
                  {Language?.insurance?.insurance_sub_title}
                </h6>
                <p>
                  <Insurance />
                </p>
                <div>
                  <Form.Check
                    // label="I waive travel protection for my booking"
                    label={Language?.insurance?.no_insurance_certificate}
                    name="insuranceCheck"
                    type="radio"
                    className="d-block mt-5"
                    onClick={() => setInsurance("waive")}
                    checked={insuranceCheck === "waive"}
                  />
                  <Form.Check
                    // label="I just got insurance for my booking"
                    label={Language?.insurance?.has_insurance_certificate}
                    // label="I have just booked insurance from HanseMerkur for my booking"
                    name="insuranceCheck"
                    type="radio"
                    className="d-block"
                    onClick={() => setInsurance("has")}
                    checked={insuranceCheck === "has"}
                  />
                </div>
                <div className="row respadding">
                  <div className="col-lg-12">
                    <button
                      disabled={!insuranceCheck}
                      onClick={() => setSelectedTab("payment-details")}
                      className="btn btn-default btnviewprojnext float-right"
                      type="button"
                    >
                      {Language?.general?.next}
                    </button>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey='payment-details'>
                <h4 className="com-font fw700 text-red">  {Language?.paymentClient?.title}</h4>
                <h6 className="com-font fw500 mb-3">{Language?.paymentClient?.subtitle}
                </h6>
                <div>
                  <Elements stripe={stripe}>
                    <ElementsConsumer>
                      {({ stripe, elements }) => (
                        <form>
                          <Row>
                          <Col md={12} lg={6}>
                              <label>{Language?.paymentClient?.card_number}</label>
                              <CardNumberElement className="form-control resforminp" />
                            </Col>
                            <Col md={12} lg={6}>
                            <label>{Language?.paymentClient?.card_owner}</label>
                              <div>
                                            <input
                                                type="text"
                                                className="form-control resforminp"
                                                placeholder={Language?.paymentClient?.card_owner_place}
                                                name={`firstName`}
                                                onChange={({target: {value}}) => {
                                                  setCardOwnerName(value);
                                                  setCardOwnerError(false);
                                                  if (value === "") setCardOwnerError(true);
                                                }}
                                              />
                                            {cardOwnerError && (<div className="invalid-feedback d-block">{Language?.paymentClient?.card_owner_err}</div>)}
                                        </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12} lg={6}>
                              <label>{Language?.paymentClient?.card_expiry}</label>
                              <CardExpiryElement className="form-control resforminp" />
                            </Col>
                            <Col md={12} lg={6}>
                              <label>{Language?.paymentClient?.card_cvc}</label>
                              <CardCvcElement className="form-control resforminp" options={{placeholder:Language?.paymentClient?.card_cvc_place||''}}   />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <button
                                onClick={() => handleSubmit(stripe, elements)}
                                className="btn btn-default btnviewprojnext float-right"
                                type="button"
                                disabled={processing}
                              >
                                {Language?.paymentClient?.verify_card}
                              </button>
                            </Col>
                          </Row>
                        </form>
                      )}
                    </ElementsConsumer>
                  </Elements>
                </div>
                {cardToken && (
                  <Row className="d-flex justify-content-end mt-5">
                    <Col className="col-md-3 col-12">
                      <FileUpload
                        label={Language?.paymentClient?.upload_passport}
                        formatLabel={Language?.paymentClient?.upload_passport_place}
                        multiple={true}
                        value={passportIdCard}
                        onChange={(val) =>
                          setPassportIdCard(val as CloudinaryImg[])
                        }
                        className={'btnSaveandStart'}
                      />
                    </Col>
                  </Row>
                )}

                {/* <div className="row respadding">
                  <div className="col-lg-12">
                    <label>{Language?.paymentClient?.recomended_by}</label>
                    <input
                      name="recommenderId"
                      type="text"
                      className="form-control resforminp"
                      placeholder={Language?.paymentClient?.recomended_by_place}
                      onChange={(e) => setRecommenderId(e.target.value)}
                    />
                  </div>
                </div> */}
                <div className="row respadding">
                  <div className="col-lg-12">
                    <button
                      disabled={!cardToken}
                      onClick={() => setSelectedTab("payment")}
                      className="btn btn-default btnviewprojnext float-right"
                      type="button"
                    >
                      {Language?.general?.next}
                    </button>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="optimization">
                <div className="row">
                  <h4 className="com-font fw700 text-red">Legal</h4>
                  <div className="col-lg-12 paddlr0 paddtermstxt com-font">
                    <h6 className="fw700">General Terms and Conditions</h6>
                    <div className="col-lg-12 paddlr0">
                      <div className="form-check resforminp">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label
                          className="form-check-label fw300 "
                          htmlFor="exampleCheck1"
                        >
                          I read and accept the following legal documents. By
                          checking this checkbox I agree to the{" "}
                          <a
                            href={documents?.terms_and_conditions.data.attributes?.url}
                            target="blank"
                          >
                            Terms & Conditions
                          </a>
                          , the{" "}
                          <a
                            href="/pdfs/travel-and-safety-info.pdf"
                            target="blank"
                          >
                            Travel & Safety Information
                          </a>
                          , the{" "}
                          <a href="/pdfs/data-policy.pdf" target="blank">
                            Data Policy
                          </a>{" "}
                          and the{" "}
                          <a
                            href="/pdfs/pkg-travel-guidelines.pdf"
                            target="blank"
                          >
                            Package Travel Guidelines Form
                          </a>{" "}
                          for me and any additional travelers in this booking.
                        </label>
                      </div>

                    </div>
                  </div>
                  <div className="col-lg-12 paddlr0 paddtermstxt com-font">
                    <h6 className="fw700">Privacy Declaration</h6>
                    <div className="col-lg-12 paddlr0">
                      <div className="form-check resforminp">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label
                          className="form-check-label fw300 "
                          htmlFor="exampleCheck1"
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur.
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 paddlr0 paddtermstxt com-font">
                    <h6 className="fw700">Policy for vacation packages</h6>
                    <div className="col-lg-12 paddlr0">
                      <div className="form-check resforminp">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                        />
                        <label
                          className="form-check-label fw300 "
                          htmlFor="exampleCheck1"
                        >
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur.
                        </label>
                      </div>
                    </div>
                  </div>
                  <a
                    href="thankyou.html"
                    className="btn btn-default btn-block btnfinalbook com-font"
                  >
                    Confirm Booking
                  </a>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="payment">
                <div className="row m-0">
                  <h4 className="com-font fw700 text-red">Checkout Payment</h4>
                  {cart.items.map((cartItem, index) => (
                    <BookingDetails cartItem={cartItem} key={index} />
                  ))}
                  <div className="row paddupcheckpay m-0">
                    <div className="col-lg-7 paddlr0 paddtermstxt com-font">
                      <h6 className="fw700">{Language?.general?.legal_documents}</h6>
                      <div className="col-lg-12 paddlr0">
                        <div className="form-check mb-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            onClick={() => setlegalDocument(!legalDocument)}
                            checked={legalDocument}
                          />
                          <label className="form-check-label fw300 ">
                          {Language?.general?.legal_agreement_1}{" "}
                            <a
                              href={documents.terms_and_conditions?.data?.attributes?.url}
                              target="blank"
                            >
                              {Language?.general?.terms_and_conditions}
                            </a>
                            , {Language?.general?.the}{" "}
                            <a href={documents.privacy_policy?.data?.attributes?.url} target="blank">
                             {Language?.general?.privacy_policy}
                            </a>{" "}
                            {Language?.general?.and} {Language?.general?.the}{" "}
                            <a
                              href={documents.package_travel_policy?.data?.attributes?.url}
                              target="blank"
                            >
                              {Language?.general?.package_travel_guidelines_form}
                            </a>{" "}
                            {Language?.general?.legal_agreement_2}  {" "}
                            <a
                              href="https://www.auswaertiges-amt.de/de/ReiseUndSicherheit/reise-und-sicherheitshinweise"
                              target="blank"
                            >
                              {Language?.general?.travel_and_safety} 
                            </a>{" "}
                            {Language?.general?.legal_agreement_3}
                          </label>

                        </div>
                        <div className="form-check mb-2">
                          <input
                            checked={hospitalityCheck}
                            onClick={() => setHospitalityCheck(!hospitalityCheck)}
                            type="checkbox"
                            className="form-check-input"
                            id="hospitalityCheck"
                          />
                          <label
                            className="form-check-label fw300 "
                            htmlFor="hospitalityCheck">
                             {Language?.general?.hospitality_agreement_1}{' '} 
                             <a target={'_blank'} href={documents?.hospitality_release.data.attributes?.url}> {' '}{Language?.general?.hospitality_release_and_waiver} </a> 
                             {Language?.general?.hospitality_agreement_2}
                          </label>
                        </div>

                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="form-group text-center">
                        <div className="text-center swt-headmain com-font text-red fontWeight700">
                          <strong>{totalPrice}€</strong>
                        </div>
                        <div className="text-center swt-headmain com-font text-red">
                        {Language?.general?.total_price}
                        </div>
                      </div>
                      <div className="row">
                        <button
                          disabled={loading || !legalDocument || !hospitalityCheck}
                          onClick={saveReservation}
                          className="btn btnfinalbook btn-block com-font"
                        >
                           {Language?.general?.final_pay_now_btn}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="reporting">
                <div className="design-process-content">
                  <h3>Reporting</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincid unt ut laoreet dolore
                    magna aliquam erat volutpat.{" "}
                  </p>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </>
  );
};