import { yupResolver } from "@hookform/resolvers/yup";
import React, { FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { RootState } from "../../store";
import { CloudinaryImg } from "../../store/Projects";
import Countries from "../../utilities/countries";
import FileUpload from "../FileUpload";

export interface CompanyFormInput {
  companyName: string;
  companyNumber: string;
  companyAddress: string;
  companyCity: string;
  companyPostalCode: number;
  companyStateProvince: string;
  companyCountry: string;
  companyContactEmail: string;
  webAddress: string;
  companyInfo: string;
  tccheck: boolean;
  dscheck: boolean;
  companyCertificate: CloudinaryImg[];
}

export const CompanyFormInputSchema = yup.object().shape({
  companyName: yup.string().required(),
  companyNumber: yup.string().required(),
  companyAddress: yup.string().required(),
  companyCity: yup.string().required(),
  companyPostalCode: yup.number().required(),
  companyStateProvince: yup.string().required(),
  companyCountry: yup.string().required(),
  companyContactEmail: yup.string().required(),
  webAddress: yup.string().required(),
  companyInfo: yup.string().required(),
  tccheck: yup.boolean().isTrue(),
  dscheck: yup.boolean().isTrue(),
  companyCertificate: yup.object().nullable(),
});

interface companyFormProps {
  initialData: CompanyFormInput;
  onNext: () => void;
  onStartOver?: () => void;
  onSave: (data: any, shoudSaveDraft: boolean) => void;
}
const CompanyForm: FunctionComponent<companyFormProps> = ({
  initialData,
  onSave,
  onStartOver,
  onNext,
}) => {
  const { register, handleSubmit, errors, control, getValues } =
    useForm<CompanyFormInput>({
      resolver: yupResolver(CompanyFormInputSchema),
      defaultValues: initialData,
    });

  const [companyCertificate, setcompanyCertificate] = useState<CloudinaryImg[]>(
    initialData?.companyCertificate || []
  );
  const [picError, setPicError] = useState<boolean>(false);

  const _onNext = (data: CompanyFormInput) => {
    if (!companyCertificate.length) {
      setPicError(true);
      return;
    }
    onSave({ ...data, companyCertificate }, false);
    onNext();
  };

  const onSaveDraft = () => {
    const data = getValues();
    onSave({ ...data, companyCertificate }, true);
  };
  const companyLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content?.company!
  );
  const genralLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content
  );
  return (
    <div className="row">
      <h4 className="com-font fw700 text-red text-capitalize">
        {genralLan?.general?.company_info}
      </h4>
      <div className="col-lg-12 paddlr0 com-font">
        <p className="small fw700">
          {genralLan?.general?.company_info_sub}
        </p>
        <form className="sa-custom-form" onSubmit={handleSubmit(_onNext)}>
          <div className="form-group">
            <div className="row respadding">
              <div className="col-lg-6">
                <label htmlFor="companyName" className="mb-0 small">
                  {companyLan?.company_name}
                </label>
                <input
                  type="text"
                  className="form-control resforminp mt-0"
                  id="companyName"
                  name="companyName"
                  placeholder={companyLan?.company_name_place}
                  ref={register}
                />
                {errors.companyName && (
                  <div className="invalid-feedback d-block">
                    {companyLan?.company_name_err}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label htmlFor="companyNumber" className="mb-0 small">
                  {companyLan?.company_num}
                </label>
                <input
                  type="text"
                  className="form-control resforminp mt-0"
                  id="companyNumber"
                  name="companyNumber"
                  placeholder={companyLan?.company_num_place}
                  ref={register}
                />
                {errors.companyNumber && (
                  <div className="invalid-feedback d-block">
                    {companyLan?.company_num_err}
                  </div>
                )}
              </div>
              <div className="col-lg-12">
                <label htmlFor="address" className="mb-0 small mt-3">
                  {companyLan?.company_address}
                </label>
                <input
                  type="text"
                  className="form-control resforminp mt-0"
                  id="companyAddress"
                  name="companyAddress"
                  placeholder={companyLan?.company_address_place}
                  ref={register}
                />
                {errors.companyAddress && (
                  <div className="invalid-feedback d-block">
                    {companyLan?.company_address_err}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label htmlFor="companycity" className="mb-0 small mt-3">
                  {companyLan?.company_city}
                </label>
                <input
                  type="text"
                  className="form-control resforminp mt-0"
                  id="companyCity"
                  name="companyCity"
                  placeholder={companyLan?.company_city_place}
                  ref={register}
                />
                {errors.companyCity && (
                  <div className="invalid-feedback d-block">
                    {companyLan?.company_city_err}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label htmlFor="postalcode" className="mb-0 small mt-3">
                  {companyLan?.postal_code}
                </label>
                <input
                  type="text"
                  className="form-control resforminp mt-0"
                  id="companyPostalCode"
                  name="companyPostalCode"
                  placeholder={companyLan?.postal_code_place}
                  ref={register}
                />
                {errors.companyPostalCode && (
                  <div className="invalid-feedback d-block">
                    {companyLan?.postal_code_err}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label htmlFor="stateProvince" className="mb-0 small mt-3">
                  {companyLan?.state}
                </label>
                <input
                  type="text"
                  className="form-control resforminp mt-0"
                  id="companyStateProvince"
                  name="companyStateProvince"
                  placeholder={companyLan?.state_place}
                  ref={register}
                />
                {errors.companyStateProvince && (
                  <div className="invalid-feedback d-block">
                    {companyLan?.state_err}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label htmlFor="country" className="mb-0 small mt-3">
                  {companyLan?.country}
                </label>
                <select
                  className="form-control resforminp mt-0"
                  id="companyCountry"
                  name="companyCountry"
                  ref={register}
                >
                  <option disabled value="">
                    {companyLan?.country_place}
                  </option>
                  {Countries.map((c) => (
                    <option value={c.name}>{c.name}</option>
                  ))}
                </select>
                {errors.companyCountry && (
                  <div className="invalid-feedback d-block">
                    {companyLan?.country_err}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label htmlFor="contactEmail" className="mb-0 small mt-3">
                  {companyLan?.contact_email}
                </label>
                <input
                  type="text"
                  className="form-control resforminp mt-0"
                  id="companyContactEmail"
                  name="companyContactEmail"
                  placeholder={companyLan?.contact_email_place}
                  ref={register}
                />
                {errors.companyContactEmail && (
                  <div className="invalid-feedback d-block">
                    {companyLan?.contact_email_err}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label htmlFor="webAddress" className="mb-0 small mt-3">
                  {companyLan?.website}
                </label>
                <input
                  type="text"
                  className="form-control resforminp mt-0"
                  id="webAddress"
                  name="webAddress"
                  placeholder={companyLan?.website_place}
                  ref={register}
                />
                {errors.webAddress && (
                  <div className="invalid-feedback d-block">
                    {companyLan?.website_err}
                  </div>
                )}
              </div>
              <div className="col-lg-12">
                <label
                  htmlFor="companyInfo"
                  className="mb-0 small mt-3 sa-label small text-capitalize"
                >
                  {companyLan?.purpose}
                </label>
                <textarea
                  id="companyInfo"
                  name="companyInfo"
                  cols={30}
                  rows={3}
                  className="form-control"
                  placeholder={companyLan?.purpose_place}
                  ref={register}
                />
                {errors.companyInfo && (
                  <div className="invalid-feedback d-block">
                    {companyLan?.purpose_err}
                  </div>
                )}
              </div>
              <div className="col-lg-12">
                <FileUpload
                  label={companyLan?.certificate}
                  formatLabel={companyLan?.certificate_place}
                  multiple={true}
                  value={companyCertificate}
                  onChange={(val) => {
                    setcompanyCertificate(val as CloudinaryImg[]);
                    setPicError(false);
                  }}
                />
                {picError && (
                  <div className="invalid-feedback d-block">
                    {companyLan?.certificate_err}
                  </div>
                )}
              </div>


              <div className="col-lg-12">
                <button
                  className="btn btn-default btnSaveandStart text-capitalize mt-3 mx-1"
                  onClick={onSaveDraft}
                >
                  {genralLan?.general?.save_as_draft}
                </button>
                <button
                  type="button"
                  className="btn btn-default btnSaveandStart text-capitalize mt-3 mx-1"
                  onClick={onStartOver}
                >
                  {genralLan?.general?.start_over}
                </button>
                <input
                  type="submit"
                  className="btn btn-default btnviewprojback text-capitalize mt-3 mx-1"
                  value={genralLan?.general?.next}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyForm;
