export interface ProjectImage {
    imageURL: string;
    public_id: string;
}

export interface CartItem {
    projectId?: number;
    projectOwnerId?: string;
    projectName?: string;
    projectDescription?: string;
    projectImage?: ProjectImage;
    tourId?: number;
    tourOwnerId?: string;
    tourName?: string;
    tourDescription?: string;
    tourImage?: TourImage;
    price: number;
    accommodation: string;
    startDate: Date;
    endDate: Date;
    numberOfNights: number;
    participants: string;
    localSupervisorPhone: string;
    contactEmail: string;
    localSupervisorName: string;
    travelSafetyUrl: string;
    isOverlapping?: boolean;
    forceConfirmed?: boolean;
    levelOfDifficulty: string;
    nearAirport: string;
    choiceOfFood: string;
    natureOfEventdescription: string;
    natureOfEvent: string;
    spokenLanguages: string;
    voucherCode: string;
    additionalPrice: string;
    airportCost: number;
    category: string;
    discountApplied: boolean;
    actualPrice: number;
}

export interface TourImage {
    imageURL: string;
    public_id: string;
}
export interface ToursCartItem {
    tourId: number;
    tourOwnerId: string;
    tourName: string;
    tourDescription: string;
    tourImage: TourImage;
    price: number;
    accommodation: string;
    startDate: Date;
    endDate: Date;
    numberOfNights: number;
    participants: string;
    localSupervisorPhone: string;
    contactEmail: string;
    localSupervisorName: string;
    travelSafetyUrl: string;
    isOverlapping?: boolean;
    forceConfirmed?: boolean;
    levelOfDifficulty: string;
    nearAirport: string;
    choiceOfFood: string;
    natureOfEventdescription: string;
    natureOfEvent: string;
    spokenLanguages: string;
    additionalPrice: string;
    voucherCode: string;
    discountApplied: boolean;
    actualPrice: number
}

export interface ICart {
    items: CartItem[];
    id: string;
    userID: string;
}
export interface IInvoice {
    bookingNo: string;
    createdAt: Date;
    updatedAt: Date;
    type: string;
    id: string;
    userID: string;
}


export interface TravelersInfo {
    gender: string;
    firstName: string;
    lastName: string;
    country: string;
    zip: string;
    address1: string;
    address2: string;
    email: string;
    phoneNumber: string;
}

export interface IReservation {
    travelersInfo: TravelersInfo[];
    id: string;
    userId: string;
    eventId: string;
    eventName: string;
    eventOwnerId: string;
    eventType: string;
    startDate: Date;
    endDate: Date;
    guests: number;
    numberOfNights: number;
    total: string;
    eventEmergencyContact: string;
    eventContactMail: string;
    eventSupervisorName: string;
    status: string;
    paid: boolean;
    invoices: IInvoice
}

export interface CartState {
    Cart?: ICart
    Reservations: IReservation[]
}
export const SET_CART_ITEMS = "SET_CART_ITEMS"
export const SET_RESERVATIONS = "SET_RESERVATIONS"


export interface SetCartItemsAction {
    type: typeof SET_CART_ITEMS,
    payload: ICart
}

export interface SetReservationsAction {
    type: typeof SET_RESERVATIONS,
    payload: IReservation[]
}

export type CartActionTypes = SetCartItemsAction | SetReservationsAction