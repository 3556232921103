import dayjs from "dayjs"

export function getReviewArray(point: number | undefined | null) {
    if (!point) return []
    const checkedArray: boolean[] = []
    for (let i = 1; i <= 5; i++) {
        checkedArray.push(i <= point)
    }
    return checkedArray
}

export const getEventType = (cartItem: any) => {
    return cartItem.projectName ? "project" : "tour"
}

export const filterEmptyStringsProps = (obj: Object) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
}

export function emptyStringToNull(value: string, originalValue: string) {
    if (typeof originalValue === 'string' && originalValue === '') {
        return null;
    }
    return value;
}
export function getLatestBookingDate(value: Date | string) {
    return dayjs(dayjs(value).startOf('D')
        .add(0, "d")
        .toDate() > dayjs().add(0, 'D').toDate() ? dayjs(value).startOf('D')
            .add(0, "d")
            .toDate() : dayjs(value).startOf('D')
                .add(dayjs().diff(dayjs(value).startOf('D'), 'day') + 1, 'd')
                .toDate())
}

export const getLocaleforDatePicker = () => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];

    return {
        localize: {
            day: (n: any) => days[n],
            month: (n: any) => months[n]
        },
        formatLong: {},
        options: {
            weekStartsOn: 1
        }
    }
}

export const getAccommodationCatgeroyName = (toConvert: string) => {
    const currentLanguage = localStorage.getItem('language') ?? 'en';
    if (currentLanguage === "en") return toConvert;
    
    else  {
        const ACCOMMODATION_CATEGORIES: any = {
            "Earth Discoverer": {
                de: "Erd-Erkunder",
                es: "Descubridor de la Tierra"
            },
            "Explorer's Retreat": {
                de: "Entdecker-Retreat",
                es: "Retiro del Explorador"
            },
            "Wanderer's Paradise": {
                de: "Weltenbummler-Paradies",
                es: "Paraíso del Trotamundos"
            },
        };
        return currentLanguage === "de" ? ACCOMMODATION_CATEGORIES[toConvert]?.de : ACCOMMODATION_CATEGORIES[toConvert]?.es
    }
}

export const getFoodChoiceName = (toConvert: string) => {
    const currentLanguage = localStorage.getItem('language') ?? 'en';
    if (currentLanguage === "en") return toConvert;
    
    else  {
        const FOOD_CHOICES: any = {
            "3 meals a day": {
                de: "3 Mahlzeiten pro Tag",
                es: "3 comidas al día"
            },
            "Snacks & fresh water": {
                de: "Snacks & frisches Trinkwasser",
                es: "Snacks y agua fresca"
            },
        };
        return currentLanguage === "de" ? FOOD_CHOICES[toConvert]?.de : FOOD_CHOICES[toConvert]?.es
    }
}

// ... existing code ...

export const getSDGTranslations = (toConvert: string) => {
    const currentLanguage = localStorage.getItem('language') ?? 'en';
    if (currentLanguage === "en") return toConvert;
    
    else {
        const SDG_TRANSLATIONS: any = {
            "1. No Poverty": {
                de: "1. Keine Armut",
                es: "1. Fin de la pobreza"
            },
            "2. Zero Hunger": {
                de: "2. Kein Hunger",
                es: "2. Hambre zero"
            },
            "3. Good Health & Well-Being": {
                de: "3. Gesundheit & Wohlergehen",
                es: "3. Salud y bienestar"
            },
            "4. Quality Education": {
                de: "4. Hochwertige Bildung",
                es: "4. Educación de calidad"
            },
            "5. Gender Equality": {
                de: "5. Geschlechtergleichheit",
                es: "5. Igualdad de género"
            },
            "6. Clean Water & Sanitation": {
                de: "6. Sauberes Wasser & Sanitäreinrichtungen",
                es: "6. Agua limpia y saneamiento"
            },
            "7. Affordable & Clean Energy": {
                de: "7. Bezahlbare und saubere Energie",
                es: "7. Energía asequible y no contaminante"
            },
            "8. Decent Work & Economic Growth": {
                de: "8. Menschenwürdige Arbeit und Wirtschaftswachstum",
                es: "8. Trabajo decente y crecimiento económico"
            },
            "9. Industry, Innovation & Infrastructure": {
                de: "9. Industrie, Innovation & Infrastruktur",
                es: "9. Industria, innovación e infraestructura"
            },
            "10. Reduced Inequalities": {
                de: "10. Weniger Ungleichheiten",
                es: "10. Reducción de las desigualdades"
            },
            "11. Sustainable Cities & Communities": {
                de: "11. Nachhaltige Städte und Gemeinden",
                es: "11. Ciudades y comunidades sostenibles"
            },
            "12. Responsible Consumption & Production": {
                de: "12. Nachhaltige/r Konsum und Produktion",
                es: "12. Producción y Consumo y responsables"
            },
            "13. Climate Action": {
                de: "13. Maßnahmen zum Klimaschutz",
                es: "13. Acción por el clima"
            },
            "14. Life below Water": {
                de: "14. Leben unter Wasser",
                es: "14. Vida submarina"
            },
            "15. Life on Land": {
                de: "15. Leben an Land",
                es: "15. Vida de ecosistemas terestres"
            },
            "16. Peace, Justice & Strong Institutions": {
                de: "16. Frieden, Gerechtigkeit und starke Institutionen",
                es: "16. Paz, justicia e instituciones sólidas"
            },
            "17. Partnerships for the Goals": {
                de: "17. Partnerschaften zur Erreichung der Ziele",
                es: "17. Alianzas para lograr los objetivos"
            },
        };
        return currentLanguage === "de" ? SDG_TRANSLATIONS[toConvert]?.de : SDG_TRANSLATIONS[toConvert]?.es
    }
}

export const getEventTypeTranslations = (toConvert: string) => {
    const currentLanguage = localStorage.getItem('language') ?? 'en';
    if (currentLanguage === "en") return toConvert;
    
    else {
        const SDG_TRANSLATIONS: any = {
            "private tour": {
                de: "private tour",
                es: "tour privado"
            },
            "group tour": {
                de: "gruppentour",
                es: "tour en grupo"
            },
            "living in a local community": {
                de: "leben in einer lokalen community",
                es: "vivir en una comunidad local"
            },
            "just you & nature": {
                de: "nur du & die Natur",
                es: "sólo tú y la naturaleza"
            }

        };
        return currentLanguage === "de" ? SDG_TRANSLATIONS[toConvert]?.de : SDG_TRANSLATIONS[toConvert]?.es
    }
}

export const translateToEng = (toConvert: string) => {
    const currentLanguage = localStorage.getItem('language') ?? 'en';
    if (currentLanguage === "en") return toConvert;
    
    else  {
            const ACCOMMODATION_FOOD_DATA: any = {
            "Erd-Erkunder": {
                de: "Earth Discoverer"
            },
            "Descubridor de la Tierra": {
                es: "Earth Discoverer"
            },
            "Entdecker-Retreat": {
                de: "Explorer's Retreat"
            },
            "Retiro del Explorador": {
                es: "Explorer's Retreat"
            },
            "Weltenbummler-Paradies": {
                de: "Wanderer's Paradise"
            },
            "Paraíso del Trotamundos": {
                es: "Wanderer's Paradise"
            },
            "3 Mahlzeiten pro Tag": {
                de: "3 meals a day"
            },
            "3 comidas al día": {
                es: "3 meals a day"
            },
            "Snacks & frisches Trinkwasser": {
                de: "Snacks & fresh water"
            },
            "Snacks y agua fresca": {
                es: "Snacks & fresh water"
            },
            "1. Keine Armut": {
                "de": "1. No Poverty"
            },
            "1. Fin de la pobreza": {
                "es": "1. No Poverty"
            },
            "2. Kein Hunger": {
                "de": "2. Zero Hunger"
            },
            "2. Hambre zero": {
                "es": "2. Zero Hunger"
            },
            "3. Gesundheit & Wohlergehen": {
                "de": "3. Good Health & Well-Being"
            },
            "3. Salud y bienestar": {
                "es": "3. Good Health & Well-Being"
            },
            "4. Hochwertige Bildung": {
                "de": "4. Quality Education"
            },
            "4. Educación de calidad": {
                "es": "4. Quality Education"
            },
            "5. Geschlechtergleichheit": {
                "de": "5. Gender Equality"
            },
            "5. Igualdad de género": {
                "es": "5. Gender Equality"
            },
            "6. Sauberes Wasser & Sanitäreinrichtungen": {
                "de": "6. Clean Water & Sanitation"
            },
            "6. Agua limpia y saneamiento": {
                "es": "6. Clean Water & Sanitation"
            },
            "7. Bezahlbare und saubere Energie": {
                "de": "7. Affordable & Clean Energy"
            },
            "7. Energía asequible y no contaminante": {
                "es": "7. Affordable & Clean Energy"
            },
            "8. Menschenwürdige Arbeit und Wirtschaftswachstum": {
                "de": "8. Decent Work & Economic Growth"
            },
            "8. Trabajo decente y crecimiento económico": {
                "es": "8. Decent Work & Economic Growth"
            },
            "9. Industrie, Innovation & Infrastruktur": {
                "de": "9. Industry, Innovation & Infrastructure"
            },
            "9. Industria, innovación e infraestructura": {
                "es": "9. Industry, Innovation & Infrastructure"
            },
            "10. Weniger Ungleichheiten": {
                "de": "10. Reduced Inequalities"
            },
            "10. Reducción de las desigualdades": {
                "es": "10. Reduced Inequalities"
            },
            "11. Nachhaltige Städte und Gemeinden": {
                "de": "11. Sustainable Cities & Communities"
            },
            "11. Ciudades y comunidades sostenibles": {
                "es": "11. Sustainable Cities & Communities"
            },
            "12. Nachhaltige/r Konsum und Produktion": {
                "de": "12. Responsable Consumption & Production"
            },
            "12. Producción y Consumo y responsables": {
                "es": "12. Responsable Consumption & Production"
            },
            "13. Maßnahmen zum Klimaschutz": {
                "de": "13. Climate Action"
            },
            "13. Acción por el clima": {
                "es": "13. Climate Action"
            },
            "14. Leben unter Wasser": {
                "de": "14. Life below Water"
            },
            "14. Vida submarina": {
                "es": "14. Life below Water"
            },
            "15. Leben an Land": {
                "de": "15. Life on Land"
            },
            "15. Vida de ecosistemas terestres": {
                "es": "15. Life on Land"
            },
            "16. Frieden, Gerechtigkeit und starke Institutionen": {
                "de": "16. Peace, Justice & Strong Institutions"
            },
            "16. Paz, justicia e instituciones sólidas": {
                "es": "16. Peace, Justice & Strong Institutions"
            },
            "17. Partnerschaften zur Erreichung der Ziele": {
                "de": "17. Partnerships for the Goals"
            },
            "17. Alianzas para lograr los objetivos": {
                "es": "17. Partnerships for the Goals"
            },
            "einfach": {
                "de": "easy"
            },
            "fácil": {
                "es": "easy"
            },
            "moderat": {
                "de": "moderate"
            },
            "moderado": {
                "es": "moderate"
            },
            "schwierig/ riskant": {
                "de": "difficult/ risky"
            },
            "difícil/ arriesgado": {
                "es": "difficult/ risky"
            },
            "extreme": {
                "de": "extrem"
            },
            "extrema": {
                "es": "extrem"
            },
            "private tour": {
                "de": "private tour"
            },
            "tour privado": {
                "es": "private tour"
            },
            "gruppentour": {
                "de": "group tour"
            },
            "tour en grupo": {
                "es": "group tour"
            },
            "leben in einer lokalen Community": {
                "de": "living in a local community"
            },
            "vivir en una comunidad local": {
                "es": "living in a local community"
            },
            "nur du & die Natur": {
                "de": "just you & nature"
            },
            "sólo tú y la naturaleza": {
                "es": "just you & nature"
            },
        };
        return currentLanguage === "de" ? ACCOMMODATION_FOOD_DATA[toConvert]?.de : ACCOMMODATION_FOOD_DATA[toConvert]?.es
    }
}

