import React, { forwardRef } from 'react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import { getLocaleforDatePicker } from '../../util';

function DatePicker({ datesToInclude, setSelectedReservationDateRange, setReservationDate,
    reservationDate, selectedReservationDateRange, includedDates, tourCapacity }) {

    const _HighlightColor = (date) => {
        if (isEmpty(datesToInclude)) return undefined;
        const currentDate = dayjs().format('YYYY-MM-DD:00:00:00');
        for (let item of datesToInclude) {
            const { start, end, capacity } = item;
            const startDate = dayjs(start).format('YYYY-MM-DD:00:00:00');
            if ((currentDate < startDate) && capacity !== 0) {
                let tempStartDate = moment(start).add(1, 'days').startOf('day');
                while (moment(tempStartDate).isSameOrBefore(moment(end))) {
                    if (moment(tempStartDate).isSame(moment(date))) {
                        return 'highlight'
                    }
                    tempStartDate = dayjs(tempStartDate).add(1, 'd').toDate()
                }
            }
        }
    }


    const _HandleNewCalendarDate = (date) => {
        for (let item of datesToInclude) {
            const currentDate = dayjs().format('YYYY-MM-DD:00:00:00');
            const { start, end, capacity } = item;
            const startDate = dayjs(start).format('YYYY-MM-DD:00:00:00');
            if ((currentDate < startDate) && capacity !== 0) {
                if (dayjs(start).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')) {
                    setSelectedReservationDateRange({ start: dayjs(start).toDate(), end: dayjs(end).toDate() });
                    break;
                }
            }
        }
        setReservationDate(date);
    }
    // const renderDayContents = (i, j) => {
    //     const tmpArr = datesToInclude.find(item => dayjs(item.date).isSame(dayjs(j), 'date'));

    //     return <>{i}<br />{tmpArr?.capacity ? `${tmpArr?.capacity}/${tourCapacity}` : null}</>
    // }
    const ReservationDateInput = forwardRef(({ onClick }, ref) => (
        <input
            ref={ref}
            defaultValue={`${dayjs(selectedReservationDateRange?.start).format("DD/MM/YYYY")} - ${dayjs(
                selectedReservationDateRange?.end
            ).format("DD/MM/YYYY")}`}
            onClick={onClick}
            className="form-control input-group-border-let-none"
            id="Date"
            aria-describedby="wishes"
        />
    ));

    return (
        <ReactDatePicker
            // renderDayContents={renderDayContents}
            selected={reservationDate}
            includeDates={includedDates}
            onChange={_HandleNewCalendarDate}
            dayClassName={_HighlightColor}
            customInput={<ReservationDateInput />}
            locale={getLocaleforDatePicker()}
            highlightDates={includedDates}
        />
    )
}

export default DatePicker