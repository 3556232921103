import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc'
import { isEmpty, range } from "lodash"
import moment from "moment"
import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react"
import { Modal , ProgressBar } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { Tooltip } from "react-tooltip"
import ProjectDateRangePicker from "../Components/dateRangePicker/ProjectRangeDatePicker"
// import DatePicker from "../Components/dateRangePicker"
import ImageBox from "../Components/ImageBox"
import { RootState } from "../store"
import { addItemToCart, CartItem, deleteItemFromCart, getAllReservationsOfSpecificProject, updateItemToCart } from "../store/Cart"
import { fetchProjectById } from "../store/Projects"
import { setCurrentDayToDayProgram, setCurrentSideBar, SideBarEnum } from "../store/SideBar"
import { getAccommodationCatgeroyName, getFoodChoiceName, getLatestBookingDate, getReviewArray, translateToEng } from "../util"
import calculateEventPrice from "../utilities/calcProjectPrice"
import { EventDetails } from "./event-details"

export const ProjectDetails: FunctionComponent = () => {
  dayjs.extend(utc)
  const { id } = useParams()
  const projectID = parseInt(id);
  const noOfParticpantsRef = useRef<HTMLSelectElement>(null)
  const dispatch = useDispatch()
  const history = useHistory();

  const Language = useSelector((state: RootState) => state.language.language?.content)
  const genralLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content
  );
  const projectDetails = useSelector((state: RootState) => state.projects.selectedProject)
  const isLoggedIn = useSelector((state: RootState) => state.authReducer.UserDetails !== undefined)
  const userDetails = useSelector((state: RootState) => state.authReducer.UserDetails);
  const macthingCartItem = useSelector((state: RootState) => state.cart.Cart?.items.find(i => i.projectId === projectID))
  const starArray = getReviewArray(2)
  // console.log('project details', projectDetails)
  const [noOfParticipant, setNoOfParticipant] = useState(1)
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: dayjs().toDate(),
    endDate: dayjs().add(1, 'd').toDate()
  })

  const [accomodation, setAccomodation] = useState("")
  const [choiceOfFood, setChoiceOfFood] = useState("")
  const [voucher, setVoucher] = useState("")
  const [addDiscount, setAddDiscount] = useState(false)
  const [discount, setDiscount] = useState<number>(0)
  const [isDiscountPercentage, setIsDiscountPercentage] = useState<boolean>(false);
  const [voucherApplied, setVoucherApplied] = useState(false);
  const [basePrice, setBasePrice] = useState<number>(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [numberOFNights, setNumberOFNights] = useState(1)
  const [projectReservations, setProjectReservations] = useState<any>([]);
  const [maxCapacity, setMaxCapacity] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const getReservationsOfProject = async () => {
    if (projectDetails) {
      try {
        const { startDate, endDate } = selectedDateRange;
        if (!startDate || !endDate) return;
        const res: any = await dispatch(getAllReservationsOfSpecificProject(projectID, moment(startDate).toDate(), moment(endDate).toDate()));

        setMaxCapacity(Number(res.data.maxCapacity));
        setNoOfParticipant(
          Number(res.data.maxCapacity) >= Number(noOfParticipant) ? Number(noOfParticipant) : Number(res.data.maxCapacity)
        );
      }
      catch (e: any) {
        toast.error(e.response.data.message);
      }
    }
  }

  useEffect(() => {
    dispatch(fetchProjectById(projectID));
  }, [])

  useEffect(() => {

    getReservationsOfProject();
  }, [projectDetails, selectedDateRange])

  useEffect(() => {
    if (projectDetails) {
      setAccomodation(projectDetails.category)
      setChoiceOfFood(projectDetails.choiceOfFood);
      const startDate = dayjs(getLatestBookingDate(projectDetails.startDate)).toDate();
      const endDate = dayjs(getLatestBookingDate(projectDetails.startDate)).add(1, 'd').toDate();
      setSelectedDateRange({ startDate, endDate })
      let { price } = calculateEventPrice('project', 1, projectDetails.category, startDate, endDate, projectDetails, 0)
      setBasePrice(price);
    }
  }, [projectDetails])

  useEffect(() => {
    if (isEmpty(projectReservations)) setNoOfParticipant(1);


  }, [selectedDateRange])


  useEffect(() => {
    if (!projectDetails) return
    const v = projectDetails.vouchers.find(item => item.voucher.toLocaleLowerCase() === voucher.toLocaleLowerCase())
    setDiscount(Number(v?.discountValue));
    setIsDiscountPercentage(v?.isPercentageDiscount || false);
    setVoucherApplied(false);
  }, [voucher, projectDetails])

  useEffect(() => {
    if (projectDetails) {
      const { startDate, endDate } = selectedDateRange
      let { numberOfNights, price } = calculateEventPrice('project', noOfParticipant, accomodation, startDate, endDate, projectDetails, 0, choiceOfFood)

      setTotalPrice(price)
      setNumberOFNights(numberOfNights)
      setVoucherApplied(false);
    }
  }, [projectDetails, accomodation, noOfParticipant, selectedDateRange, discount, choiceOfFood])

  if (!projectDetails) {
    return null
  }

  const accommodationOptions = [
    {
      text: projectDetails.category,
      value: projectDetails.category
    },
    ...projectDetails.extraAccommodation.map(acc => ({ text: acc.category, value: acc.category }))
  ]

  const choiceOfFoodOptions = [
    {
      text: projectDetails?.choiceOfFood,
      value: projectDetails?.choiceOfFood
    },

    ...projectDetails.extraChoiceOfFood?.map(acc => ({ text: acc.category, value: acc.category }))
  ]
  const { projectPictures, accommodationPicture, transportationPicture } = projectDetails
  const allPictures = [...projectPictures, ...accommodationPicture, ...transportationPicture]

  const getCartItem = () => {
    const { startDate, endDate } = selectedDateRange
    // console.log("start and end date", startDate.getTimezoneOffset(), endDate.getUTCDate())
    const cartItem: CartItem = {
      projectId: projectDetails.id,
      projectOwnerId: "",
      projectName: projectDetails.projectName,
      projectDescription: projectDetails.projectDescription,
      projectImage: projectDetails.accommodationPicture[0],
      price: totalPrice,
      startDate: startDate,
      endDate: endDate,
      numberOfNights: numberOFNights,
      participants: String(noOfParticipant),
      localSupervisorPhone: projectDetails.localSupervisorPhone,
      contactEmail: projectDetails.contactEmail,
      localSupervisorName: projectDetails.localSupervisorName,
      accommodation: accomodation,
      travelSafetyUrl: projectDetails.location,
      levelOfDifficulty: projectDetails.levelOfDifficulty,
      nearAirport: projectDetails.nearAirport,
      natureOfEvent: projectDetails.natureOfEvent,
      spokenLanguages: projectDetails.spokenLanguages,
      natureOfEventdescription: projectDetails.natureOfEventdescription,
      choiceOfFood,
      voucherCode: voucher,
      additionalPrice: projectDetails.additionalPrice,
      airportCost: projectDetails.airportCost,
      category: projectDetails.category,
      discountApplied: discount && voucherApplied ? true : false,
      actualPrice: discount ? Number(totalPrice + discount) : totalPrice,
    }
    return cartItem
  }
  const addToCart = async (isBookNow: boolean = false) => {
    if (userDetails?.swt_role === 'provider') {
      return toast.error("You are logged in as a provider. You need to create a normal user account to be able to make a booking with Small World Travel")
    }
    if (dayjs(selectedDateRange.startDate).format('M/D/YYYY') === dayjs(selectedDateRange.endDate).format('M/D/YYYY')) {
      return
    }
    if (isLoggedIn === false) {
      dispatch(setCurrentSideBar(SideBarEnum.Login))
    }
    const cartItem: CartItem = getCartItem()
    console.log("cart item", cartItem)
    await dispatch(addItemToCart(cartItem))
    if (isBookNow) {
      history.push('/cart')
    } else {
      dispatch(setCurrentSideBar(SideBarEnum.Cart))
    }
  }

  const removeFromCart = async () => {
    await dispatch(deleteItemFromCart(projectID))
    dispatch(setCurrentSideBar(SideBarEnum.Cart))
  }

  const updateCart = async () => {
    const cartItem: CartItem = getCartItem()
    await dispatch(updateItemToCart(cartItem))
    dispatch(setCurrentSideBar(SideBarEnum.Cart))
  }

  const onDaytoDayProgram = () => {
    dispatch(setCurrentDayToDayProgram(projectDetails))
  }

  const bookNowCLick = () => {
    noOfParticpantsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    noOfParticpantsRef.current?.focus()
  }
  
  const handleDiscount = () => {
    setVoucherApplied(true);
    if (isDiscountPercentage) {
      const discountValue = (totalPrice * discount) / 100;
      const finalAmount = totalPrice - discountValue;
      setTotalPrice(Math.round(finalAmount));
    } else setTotalPrice(totalPrice - (discount ? discount : 0));
  }

  return (
    <div className="container">
      {showModal && 
      <Modal show={showModal ? true : false} onHide={() => setShowModal(false)} className="day-to-day-modal m-5">
        <div
          id="btn-close-modal"
          className="close-animatedModalReadMore closespan"
          onClick={() => setShowModal(false)}
        >
          <div className="crossmarkx mr-3">x</div>
        </div>
        <EventDetails fromModal={true} />  
      </Modal>}
      <div className="row projsecpaddup">
        <div className="col-12">
          <ImageBox Images={allPictures} />
        </div>
      </div>
      <div className="row com-font">
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="swt-headmain com-font text-uppercase">
                {projectDetails.projectName}
              </div>
              <div className="readmorelink">
                <button onClick={() => setShowModal(true)} className="btn text-dark p-0 btn-link text-sm">{`${Language?.general?.read_more}`}</button>
              </div>
            </div>
            <div className="col-lg-6">
              <a
                href="javascript:void(0)"
                onClick={bookNowCLick}
                className="btn btn-sm btnsmbook float-right"
              >
                {Language?.general?.book_now}
              </a>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 d-none">
                  {/* <button class="circleplus circleplus-color btn btn-sm"><i class="fa fa-plus"
            aria-hidden="true"></i></button> */}
                  <button className="btn herrticon btn-sm">
                    <i className="far fa-heart" />
                  </button>
                  <div className="row projectBooking">
                    <div className="col-lg-12 rate">
                      <div className="star-ratingproj text-left">
                        {starArray.map((checked) => (
                          <span
                            className={`fa fa-star ${checked ? "checked" : ""
                              }`}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="col-lg-12 proj-detail-text com-font">
                      {Language?.general?.customer_reviews}
                    </div>
                    <div className="col-md-12">
                      <a
                        href="javascript:void(0)"
                        onClick={onDaytoDayProgram}
                        className="btn-link"
                      >
                        <img src="/images/leaflet_icon.svg" alt="" />
                        <span className="daytxt">Day-to-day program</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 float-right text-right">
                  <img src="/images/suv-car.svg" className="img-fluid" alt="" />
                  <span className="datetxt" style={{ fontSize: "1.3em" }}>
                    {getLatestBookingDate(projectDetails.startDate).format('D')}
                  </span>
                  <span className="monthtxt">
                    {getLatestBookingDate(projectDetails.startDate).format('MMMM')}
                  </span>
                  <span className="posplaneic">

                    <p className="mt-2 mb-0 loctxt">
                      <img src="/images/blackmap.svg" className="img-fluid" alt="" />{" "}
                      {projectDetails.location}
                    </p>
                    {/* <span className="small daytxt">7 days</span> */}
                    <div className="mt-1">
                      <div className="font-weight-bold my-2 pricetxt">
                        {Language?.general?.from}  {basePrice}€/{Language?.general?.night}
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="details"></div>
      <div className="row paddformupdwn">
        <div className="col-md-6 col-lg-6 ">
          <div className="swt-headmain com-font text-uppercase bluetext">
            {Language?.general?.select} {Language?.general?.your} {Language?.general?.preferences}
          </div>
          <div className="row textimgprojpaddud com-font">
            <div className="col-lg-12 proj-detail-text text-justify">
              <form id="projectBooking">
                <div className="form-group">
                  <label className="text-dark" htmlFor="datePicker">{Language?.general?.select} {Language?.general?.date}</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend icon-with-select">
                      <img className="input-group-text input-group-icon p-2" src="/images/calendar_icon.svg" alt="calendar-icon" />
                    </div>

                    {projectDetails && (
                      <ProjectDateRangePicker
                        projectDetails={projectDetails}
                        selectedDateRange={selectedDateRange}
                        setSelectedDateRange={setSelectedDateRange}
                      />
                    )}
                    {dayjs(selectedDateRange.startDate).format('M/D/YYYY') === dayjs(selectedDateRange.endDate).format('M/D/YYYY') &&
                      <span className="invalid-feedback d-block">
                        Invalid Date
                      </span>
                    }
                  </div>
                </div>
                <div className="form-group">
                  <label className="text-dark" htmlFor="selectPartipants">{Language?.general?.select} {Language?.general?.participant}</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend icon-with-select">
                      <img className="input-group-text input-group-icon p-2" src="/images/user_icon.svg" alt="user-icon" />
                    </div>
                    <select
                      ref={noOfParticpantsRef}
                      className="form-control input-group-border-let-none"
                      id="selectPartipants"
                      aria-describedby="basic-addon1"
                      value={noOfParticipant}
                      onChange={(e) =>
                        setNoOfParticipant(parseInt(e.target.value))
                      }
                    >
                      {
                        range(1, (Number(maxCapacity) + 1))
                          .map((i, index) => (
                            <option key={index} value={i.toString()}>{i}</option>
                          ))}
                    </select>

                  </div>
                </div>
                <div className="form-group">
                  <label className="text-dark" htmlFor="selectcategory">
                    {Language?.general?.accomodation_categories}
                  </label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend icon-with-select">
                      <img className="input-group-text input-group-icon p-2" src="/images/accomodation_icon.svg" alt="bed-icon" />
                    </div>
                    <select
                      className="form-control input-group-border-let-none"
                      id="selectcategory"
                      aria-describedby="category"
                      value={accomodation}
                      onChange={(e) => setAccomodation(e.target.value)}
                    >
                      {accommodationOptions.map((acc, i) => (
                        <option value={translateToEng(acc.value)}>{getAccommodationCatgeroyName(acc.text)}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label className="text-dark" htmlFor="selectchoiceOfFood">
                    {Language?.project?.choice_of_food}
                  </label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend icon-with-select">
                      <img className="input-group-text input-group-icon p-2" src="/images/meal_icon.svg" alt="meal-icon" />
                    </div>
                    <select
                      className="form-control input-group-border-let-none"
                      id="choiceOfFood"
                      aria-describedby="choiceOfFood"
                      value={choiceOfFood}
                      onChange={(e) => setChoiceOfFood(e.target.value)}
                    >
                      {choiceOfFoodOptions?.map((acc, i) => (
                        <option value={translateToEng(acc.value)}>{getFoodChoiceName(acc.text)}</option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* <div className="form-group">
                                    <label className="text-dark" htmlFor="selectwishes">Personal wishes</label>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text input-group-icon" id="wishes">
                                                <i className="fas fa-circle-notch" />
                                            </span>
                                        </div>
                                        <input
                                            className="form-control input-group-border-let-none"
                                            id="selectwishes"
                                            aria-describedby="wishes"
                                            value={personalWishes}
                                            onChange={e => setPersonalWishes(e.target.value)} />
                                    </div>
                                </div> */}
                <div className="form-group">
                  {!addDiscount && <u className="accopentxt text-dark" onClick={() => setAddDiscount(true)}>{Language?.general?.add} {Language?.general?.discount} {`${Language?.general?.code}?`}</u>}
                  {addDiscount && projectDetails.vouchers?.length &&
                    <div className="mb-3">
                      <label className="text-dark">
                        {Language?.general?.discount} {Language?.general?.code}:
                      </label>
                      <div className="d-flex">
                        <div className="input-group mr-3">
                          <div className="input-group-prepend icon-with-select">
                            <img className="input-group-text input-group-icon p-2" src="/images/voucher_icon.svg" alt="meal-icon" />
                          </div>
                          <input type="text" className="form-control icon-with-select input-group-border-let-none" placeholder={`${Language?.general?.discount_place}`} onChange={(e) => setVoucher(e.target.value)} />
                        </div>
                        <button
                            onClick={() => handleDiscount()}
                            className="btn btn-default btnSaveandStart com-font w-25"
                            type="button"
                            disabled={voucherApplied}
                        >
                          {Language?.general?.apply || 'Apply'}
                        </button>
                      </div>
                      {voucherApplied && !discount && <div className="text-sm text-muted mt-1">{Language?.general?.discount_place_err}</div>}
                    </div>
                    }
                  <div className="text-center swt-headmain com-font text-red fontWeight700">
                    <strong>{totalPrice}€</strong>
                  </div>
                  <div className="text-center swt-headmain com-font text-red">
                    {Language?.general?.total} {Language?.general?.price}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      {macthingCartItem ? (
                        <button
                          onClick={removeFromCart}
                          className="btn btn-sm btn-default btnfinalbook btn-block compaddbtns btn-danger"
                          type="button"
                          disabled={
                            userDetails?.swt_role === "provider" || !maxCapacity
                              ? true
                              : false
                          }
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>{" "}
                          {Language?.general?.remove} {Language?.general?.from} {Language?.general?.cart}
                        </button>
                      ) : (
                        <button
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Provider cannot book any project"
                          onClick={() => addToCart(false)}
                          className="btn btn-sm btn-default btnviewproj btn-block compaddbtns"
                          type="button"
                          disabled={
                            userDetails?.swt_role === "provider" || !maxCapacity
                              ? true
                              : false
                          }
                        >
                          {Language?.general?.save_to_mycart}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      {macthingCartItem ? (
                        <button
                          onClick={updateCart}
                          className="btn btn-sm btn-default btnviewproj btn-block compaddbtns"
                          type="button"
                          disabled={
                            userDetails?.swt_role === "provider"
                              ? true
                              : false
                          }
                        >
                          {Language?.general?.update} {Language?.general?.cart}
                        </button>
                      ) : (

                        <button
                          onClick={() => addToCart(true)}
                          className="btn btn-default btn-block btnfinalbook com-font"
                          type="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Provider cannot book any project"
                          disabled={
                            userDetails?.swt_role === "provider" || !maxCapacity
                              ? true
                              : false
                          }
                        >
                          {!maxCapacity ? "Reserved" :
                            Language?.general?.book_now}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6">
          <div className="swt-headmain com-font text-uppercase bluetext">
            {Language?.general?.choosen_project_details}
          </div>
          <div className="col">
            <label className="text-dark">
              {Language?.general?.risk_level}<span className="text-danger">*</span>
            </label>
            <div id="riskLevel" className="mb-3">
              <ProgressBar>
                {projectDetails?.levelOfDifficulty === 'easy' && <ProgressBar style={{ backgroundColor: '#A3A3A3' }} now={25} label={'Easy'} />}
                {projectDetails?.levelOfDifficulty === 'moderate' && <ProgressBar style={{ backgroundColor: '#5AFF15' }} now={50} label={'Moderate'} />}
                {projectDetails?.levelOfDifficulty === 'difficult/ risky' && <ProgressBar style={{ backgroundColor: '#6A66A3' }} now={75} label={"Difficult"} />}
                {projectDetails?.levelOfDifficulty === 'extreme' && <ProgressBar style={{ backgroundColor: '#F52F57' }} now={100} label={'Extreme'} />}
              </ProgressBar>
            </div>
            <Tooltip anchorSelect="#riskLevel" content={Language?.general?.risk_level_helper} />
            {/* <p className="text-muted">
              <span className="text-danger">*</span>{Language?.general?.risk_level_helper}</p> */}
          </div>
          <div className="row textimgprojpaddud com-font px-4 text-dark">

            <div className="col-lg-12 proj-detail-text text-justify">
              <ul className="p-0">
                <li>{noOfParticipant} {Language?.general?.person}</li>
                <li>
                  {dayjs(selectedDateRange.startDate).format("DD.MM.YYYY")} -{" "}
                  {dayjs(selectedDateRange.endDate).format("DD.MM.YYYY")}
                </li>
                <li>{numberOFNights} {Language?.general?.nights}</li>
                <li>
                  {Language?.general?.type_of} {Language?.general?.project}:
                  {projectDetails.natureOfEvent}
                </li>
                <li>
                  {Language?.project?.near_airport}:
                  {projectDetails.nearAirport}
                </li>
                <li>
                  {Language?.project?.spoken_languages}:
                  {projectDetails.spokenLanguages}
                </li>
                <li>
                  {Language?.project?.additional_cost}:{' '}
                  {projectDetails.additionalPrice}
                </li>
                {/* <li> */}
                  <li>
                    {choiceOfFood === '3 times a day meals' || choiceOfFood === 'three-times-a-day meals provided' ? "3 times a day meal - meals cooked from regional vegetables" : "snacks/water - snacks & fresh drinking water"}
                  </li>
                  {!accomodation?.includes('accommodation') ? accomodation?.concat(' accommodation') : accomodation}
                  <ul style={{ paddingLeft: "0px" }} className="list-none ">

                    {Language?.project?.standards && Language?.project?.standards[accomodation]?.map((item: any) =>
                      <li>
                        <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"} style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                        {item.value}
                      </li>
                    )}
                  </ul>

                {/* </li> */}
                {/* <li>Personal Wishes: {personalWishes}</li> */}
              </ul>
              <div className="includeprojtxt">{Language?.general?.included_standards}</div>
              {Language?.general?.projectStandards?.map((item: any) =>
                <li className="list-none ">
                  <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"} style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                  {item.text}
                </li>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
