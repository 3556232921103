import React, { FunctionComponent, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../store";
import {
  CartItem,
  deleteItemFromCart,
  deleteTourItemFromCart,
} from "../../store/Cart";
import { Project } from "../../store/Projects";
import { Tours } from "../../store/Tours";
import { getEventType } from "../../util";
import { useMediaQuery } from "react-responsive";


interface DaySideBarProps {
  show: boolean;
  onClose: () => void;
}
export const DayToDaySideBar: FunctionComponent<DaySideBarProps> = ({
  show,
  onClose,
}) => {
  const isMobileView = useMediaQuery({ maxWidth: 991 });


  const width = isMobileView ? '100%' : '50%';
  const style = { width: show ? width : "0px" };
  
  const dispatch = useDispatch();

  const currentEvent = useSelector(
    (rootState: RootState) => rootState.sideBar.currentEvent
  );
  const selectedLan = useSelector((state: RootState) => state.language.language?.language)

  const event = useMemo(() => {
    if (!currentEvent) return;
    const eventType = getEventType(currentEvent);
    if (eventType === "project") {
      const project = currentEvent as Project;
      return {
        type: "project",
        typicalDay: selectedLan === 'en' ? project.typicalDay : selectedLan === 'de' ? project.typicalDay_de : project.typicalDay_es,
        
      };
    } else {
      const tour = currentEvent as Tours;
      return {
        type: "tour",
        dayToDay: selectedLan === 'en' ? tour.dayToDay : selectedLan === 'de' ? tour.dayToDay_de : tour.dayToDay_es,
      };
    }
  }, [currentEvent]);
  if (!currentEvent) return <></>;

  return (
    <div className="sidenav com-font" style={style}>
      <a
        href="javascript:void(0)"
        className="closebtn closex"
        onClick={onClose}
      >
        ×
      </a>
      <div className="col-lg-12">
        <div className="searchfiltertxt pb-5">
          {event?.type == "project"
            ? "Typical Day in a Project"
            : "Day-to-Day Program"}
        </div>
        {event?.type === "project" ? (
          <div className='text-white'>{event?.typicalDay}</div>
        ) : (
          event?.dayToDay?.map((day,i) => (
            <div className="text-white">
                <div className="redtext font-weight-bold h4">Day {i+1}</div>
              <div className="font-weight-bold">{day.city}</div>
              <div className="mb-2">{day.value}</div>
            </div>
          ))
        )}
        <div></div>
      </div>
    </div>
  );
};
