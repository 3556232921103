import { sortBy } from "lodash";
import dayjs from "dayjs";
import { getLatestBookingDate } from "../../util";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Row, Col, Image } from "react-bootstrap";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import SWTButton from "../../Components/SWTButton";

type UpcomingProjectsAndToursProps = {
  title: string;
  events: any[];
  homepage?: boolean;
};
const UpcomingProjectsAndTours = ({
  title,
  events,
  homepage = false,
}: UpcomingProjectsAndToursProps) => {
  const ELEMENTS_TO_SHOW_DEFAULT = 3;
  const genralLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content?.general!
  );
  const [elementsToShow, setElementsToShow] = useState(
    ELEMENTS_TO_SHOW_DEFAULT
  );

  if (!events) {
    return null;
  }

  return (
    <>
      <div className="ourhead com-font text-red fw700 text-center">{title}</div>
      <Row style={{ margin: "0rem 1rem" }}>
        {events.slice(elementsToShow - 3, elementsToShow).map((event: any) => (
          <ProjectCard event={event} />
        ))}
      </Row>

      {homepage && (
        <div style={{ display: "flex", justifyContent: "center", marginBottom: '2rem' }}>
          <SWTButton
            icon={FaArrowLeft}
            disabled={elementsToShow - 1 < 3}
            onClick={() => {
              setElementsToShow(elementsToShow - 1);
            }}
          />
          <SWTButton
            onClick={() => {
              window.location.href = "/events";
            }}
          >
            {genralLan?.explore_all}
          </SWTButton>
          <SWTButton
            icon={FaArrowRight}
            disabled={elementsToShow + 1 > events.length}
            onClick={() => {
              setElementsToShow(elementsToShow + 1);
            }}
          />
        </div>
      )}
    </>
  );
};

export const ProjectCard = ({ event }: any) => {
  const genralLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content?.general!
  );
  const Language = useSelector(
    (state: RootState) => state.language.language?.content
  );

  let latestDate = null;
  if (event.includedEventsDates) {
    latestDate = sortBy(event.includedEventsDates, (d) => d.start).find(
      (item) => dayjs().isBefore(item.start) && item?.capacity
    );
  }

  const isMobileView = useMediaQuery({ maxWidth: 400 });
  const scale = isMobileView ? 0.75 : 1;

  return (
    <Col xs={12} sm={12} md={12} lg={6} xl={4} key={event.id}>
      <div
        style={{
          margin: "1rem 0rem",
          border: "2px solid #6a66a3",
          borderRadius: "1rem",
          padding: "0.25rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0.5rem",
            minHeight: "4rem",
          }}
        >
          <div
            style={{
              width: "2rem",
              height: "2rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Image
              src={`/images/${event.eventType}s_icon.svg`}
              fluid
              style={{ maxHeight: "100%", maxWidth: "100%" }}
              alt=""
            />
          </div>
          <span className="font-weight-bold ml-2" style={{ color: "#58548C" }}>
            {event.name}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0 16px",
            marginBottom: "0.5rem",
          }}
        >
          <div style={{ flex: "1", paddingRight: "1.25rem" }}>
            <div className="d-flex flex-column">
              <p className="mb-0 loctxt">
                <img src="/images/blackmap.svg" className="img-fluid" alt="" />{" "}
                {event.location}
                {event?.eventType === "tour" && <> | {event?.duration} days</>}
              </p>

              <div style={{ display: "flex", alignContent: "center" }}>
                <img src="/images/suv-car.svg" className="img-fluid" alt="" />
                <span className="monthtxt" style={{ marginTop: "0.5rem" }}>
                  {latestDate !== null
                    ? dayjs(latestDate?.start).format("MMM")
                    : getLatestBookingDate(event.startDate).format("MMM")}
                </span>
                <span className="datetxt">
                  {latestDate !== null
                    ? dayjs(latestDate?.start).format("D")
                    : getLatestBookingDate(event.startDate).format("D")}
                </span>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div
                  className="font-weight-bold mt-2 pricetxt"
                  style={{ color: "#6A66A3", fontSize: `${1.25 * scale}rem` }}
                >
                  {genralLan?.from}{" "}
                  {event?.eventType === "tour"
                    ? `${event.basePrice}€`
                    : `${event.basePrice}€/${Language?.general?.nights}`}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="text-muted small">
                  {genralLan?.plus}{" "}{`${event?.airportCost}€`}{" "}{genralLan?.airport_transport}
                </div>
              </div>
              <div className="d-flex mt-2">
                <img src="/images/all_inclusive_icon.svg" width={40} className="img-fluid mr-1" alt="" />
                <img src="/images/home_icon.svg" width={40} className="img-fluid  mr-1" alt="" />
                <img src="/images/food_icon.svg" width={40} className="img-fluid  mr-1" alt="" />
                <img src="/images/tour_guide_icon.svg" width={40} className="img-fluid " alt="" />
              </div>
              {/* <p
                style={{
                  fontSize: "75%",
                  color: "#F52F57",
                  textTransform: "capitalize",
                }}
              >
                *food and transport included
              </p> */}
            </div>
          </div>

          <img
            style={
              isMobileView
                ? { objectFit: "cover", width: "100px", height: "100px" }
                : { objectFit: "cover", width: "200px", height: "200px" }
            }
            className="ml-auto projimag"
            src={event.pictures[0]?.imageURL}
            alt="..."
          />
        </div>
        <div
          className="mb-2 mt-4 col-lg-12"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <SWTButton onClick={event.onsideNav}>
            {genralLan?.view}{" "}
            {event.eventType === "project"
              ? genralLan?.project
              : genralLan?.tour}
          </SWTButton>
        </div>
      </div>
    </Col>
  );
};

export default UpcomingProjectsAndTours;
