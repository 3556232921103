import dayjs from "dayjs";

export default function calculateEventPrice(
  eventType: "project" | "tour",
  participants: number,
  accommodation: string,
  startDate: Date,
  endDate: Date,
  event: any,
  discount: number,
  choiceOfFood?: string
) {
  const numberOfNights = dayjs(endDate).diff(startDate, 'd');
  const price = getPrice(
    numberOfNights,
    participants,
    accommodation,
    event,
    eventType,
    discount,
    choiceOfFood
  )!;

  return { numberOfNights, price };
}

function getPrice(
  numberOfNights: any,
  participants: any,
  accommodation: any,
  event: any,
  eventType: string,
  discount: number,
  choiceOfFood?: string
) {
  if (eventType === "project") {
    return getProjectPrice(numberOfNights, participants, accommodation, event, discount, choiceOfFood);
  } else if (eventType === "tour") {
    return getTourPrice(numberOfNights, participants, accommodation, event, discount, choiceOfFood);
  }
}

function getTourPrice(
  numberOfNights: number,
  participants: number,
  accommodation: any,
  event: { category: any; consumerPrice: number; price: number; airportCost: number; continent: string, foodPrice: number},
  discount: number,
  choiceOfFood?: string
) {
  let price = 0;
  let extraPrice = 0;
  const airportCost = event.airportCost ? event.airportCost : 0;
  let foodPrice = 0;
  
  console.log('selection', choiceOfFood, choiceOfFood?.includes('Snacks'));
  if(!choiceOfFood?.includes('Snacks')) {
    foodPrice = event.foodPrice ? Number(event.foodPrice) : 0;
  }

  if (accommodation !== event.category) {
    extraPrice = findExtraPrice(accommodation, event)!;
    extraPrice = extraPrice * numberOfNights;
  }

  if (event.continent === 'Europe') {
    price = (participants * (event.price + extraPrice + foodPrice) + airportCost) * 1.42 * 1.19
  } else {
    price = (participants * (event.price + extraPrice + foodPrice) + airportCost) * 1.42
  }

  return Math.round(discount ? price - Number(price) * discount/100 : price);
}

function getProjectPrice(
  numberOfNights: number,
  participants: number,
  accommodation: any,
  event: {
    category: any;
    consumerPrice: number;
    consumerPricePerDay: number;
    consumerPricePerMonth: number;
    pricePerDay: number;
    continent: string;
    airportCost: number;
    foodPrice: number;
  },
  discount: number,
  choiceOfFood?: string
) {

  let price = 0;
  let extraPrice = 0;
  const airportCost = event.airportCost ? Number(event.airportCost) : 0;
  let foodPrice = 0;
  
  if(!choiceOfFood?.includes('Snacks')) {
    foodPrice = event.foodPrice ? Number(event.foodPrice) : 0;
  }

  if (accommodation !== event.category) {
    extraPrice = findExtraPrice(accommodation, event)!;
  }

  const priceZeroNights = (participants * (event.pricePerDay + extraPrice + foodPrice) + airportCost) * 1.42;
  const priceWithNights = (participants * ((numberOfNights * event.pricePerDay) + (extraPrice * numberOfNights) + (foodPrice * numberOfNights)) + airportCost) * 1.42

  if (event.continent === 'Europe') {
    price = numberOfNights === 0 ?
      priceZeroNights * 1.19 : priceWithNights * 1.19
  } else {
    price = numberOfNights === 0 ? priceZeroNights : priceWithNights
  }

  return Math.round(discount ? price - Number(price) * discount/100 : price);
}

function findExtraPrice(accommodation: any, event: any) {
  let i = 0;
  while (i < event.extraAccommodation?.length) {
    if (event.extraAccommodation[i].category === accommodation) {
      return Number(event.extraAccommodation[i].extraPrice);
    }
    i++;
  }
}
