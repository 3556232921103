import dayjs from "dayjs";
import _ from "lodash";
import queryString from "query-string";
import { FunctionComponent, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import useInterval from "../hooks/useInterval";
import { RootState } from "../store";
import {
  Ifilter,
  searchEvents,
  setCategory,
  setEndDate,
  setEventType,
  setFilter,
  setLocation,
  SetQualifications,
  setStartDate
} from "../store/search";
import {
  setCurrentDayToDayProgram,
  setCurrentEvent,
  setCurrentSideBar,
  SideBarEnum
} from "../store/SideBar";
import { getEventType, getLatestBookingDate } from "../util";
import { Spinner } from "../Components/Spinner";
import { isEmpty } from 'lodash';
import calculateEventPrice from "../utilities/calcProjectPrice";

interface ProjectCardProps {
  id: string;
  projectName: string;
  price: number;
  startDate: any;
  location: string;
  picture: string;
  event: any;
}

const ProjectCard: FunctionComponent<ProjectCardProps> = ({
  picture,
  projectName,
  price,
  startDate,
  location,
  event,
}) => {
  const dispatch = useDispatch();

  const onDaytoDayProgram = () => {
    dispatch(setCurrentDayToDayProgram(event));
  };
  const eventType = getEventType(event);

  const onViewClick = () => {
    dispatch(setCurrentEvent(event));
  };
  const Language = useSelector((state: RootState) => state.language.language?.content)
  const selectedLan = useSelector((state: RootState) => state.language.language?.language)

  return (
    <li className="list-group-item">
      {/* Custom content*/}
      <div className="media align-items-lg-center flex-column flex-lg-row ">
        <div className="media-body order-2 order-lg-1">
          {/*  <label>#Project</label> */}
          <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
              <div className="row">
                <div className="mt-0 font-weight-bold  projname col-xl-7 col-lg-8 col-md-8 col-sm-7">
                  <span>
                    <img
                      src={`/images/${eventType}s_icon.svg`}
                      className="img-fluid tpic"
                      alt=""
                    />
                  </span>
                  {projectName}
                </div>
                <div className="text-right col-xl-5 col-lg-4 col-md-4 col-sm-5">
                  <span className="posplaneic">
                    <img src="/images/suv-car.svg" className="img-fluid" alt="" />
                    <span className="monthtxt">
                      .{dayjs(startDate).format("MMM")}
                    </span>
                    <span className="datetxt">
                      {dayjs(startDate).format("D")}
                    </span>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  {eventType === 'tour' && <span className="small daymod pl-0">{event?.duration} days</span>}
                  <p className="mb-0 loctxt">
                    <img src="/images/blackmap.svg" className="img-fluid" alt="" />{" "}
                    {location}
                  </p>
                  {event.daytoDay && event.daytoDay.length > 0 && (
                    <span className="small daytxt">
                      {event.daytoDay.length} {Language?.general?.days}
                    </span>
                  )}
                  <div className="d-flex justify-content-between mt-1">
                    <div className="font-weight-bold my-2 pricetxt">
                      {Language?.general?.from}  {price}€
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">

                <div className="col-xl-12 col-sm-12 col-xs-12">
                  <button
                    onClick={onViewClick}
                    className="btn btn-sm btnviewproj"
                  >
                    {Language?.general?.view} {eventType === "project" ? Language?.general?.project : Language?.general?.tour}
                  </button>
                </div>

                {/* <button className="circleplus btn btn-sm"><i className="fa fa-plus" aria-hidden="true" /></button>
                              <button className="btn heartic btn-sm"><img src="/images/heart_icon.svg" className="img-fluid maxhprojlist" /></button> */}

                {/* <div className="col-xl-6 col-sm-6 col-xs-12">
                <Link to={`/events/${eventType}s/${event.id}`}
                  // href="javascript:void(0)"
                  // onClick={onDaytoDayProgram}
                  className="btn-link"
                >
                  <img width={50} src="/images/leaflet_icon2.svg" alt="" />
                  <span className="daytxt">{Language?.general?.view_day_to_day} {Language?.general?.program}</span>
                </Link>
              </div> */}

              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 mt-1 text-center">
              <img style={{ objectFit: 'cover' }} src={picture} className="img-fluid projimag" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* End */}
    </li>
  );
};

export const Events: FunctionComponent = () => {

  const [switchMap, setSwitchMap] = useState('projects')
  useInterval(() => {
    if (switchMap === 'projects') {
      setSwitchMap('tours')
    } else {
      setSwitchMap('projects')
    }
  }
    , 5000);

  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const filter = useSelector((rootState: RootState) => rootState.searchReducer.filter);
  const [loading, setLoading] = useState(true);

  const events = useSelector((rootState: RootState) => rootState.searchReducer.events);
  const Language = useSelector((state: RootState) => state.language.language?.content)
  const selectedLan = useSelector((state: RootState) => state.language.language?.language)

  const { eventType, location } = filter;

  useEffect(() => {

    enableLoading();

    let params = queryString.parse(search) as Ifilter;
    console.log("paramas", filter, params)
    params.eventType ? dispatch(setEventType(params.eventType)) : dispatch(setEventType("All"));
    params.category && dispatch(setCategory(params.category));
    params.qualifications && dispatch(SetQualifications(params.qualifications));
    params.startDate && dispatch(setStartDate(params.startDate));
    params.endDate && dispatch(setEndDate(params.endDate));
    params.location && dispatch(setLocation(params.location));

    dispatch(searchEvents());

    //will rewiew
    return () => {
      dispatch(setLocation(undefined));
      dispatch(setEventType("Project"));
      dispatch(setCategory(undefined));
      dispatch(SetQualifications(undefined));
      dispatch(setStartDate(undefined));
      dispatch(setEndDate(undefined));
    };
  }, [search]);

  useEffect(() => {
    if (!isEmpty(Language?.general?.filters_applied)) {
      disableLoading();
    }
  }, [])

  const removeFilter = (key: string) => {
    if (["eventType"].includes(key) === false) {
      const copyOfFilter = { ...filter };
      delete (copyOfFilter as any)[key];
      history.push({
        pathname: '/events',
        search: Object.entries(copyOfFilter).filter(([_, value]) => value).map(([key, value]) => `${key}=${value}`).join('&')
    })
      dispatch(setFilter(copyOfFilter));
      dispatch(searchEvents());
    }
  };

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <>
      <div className="col-lg-12"></div>
      <div className="col-lg-12 bgcom paddlr0">
        <div className="col-lg-12">
          <div className="container">
            <div className="row project-tourlist com-font paddinglistingsswt">
              <div className="col-lg-10" style={{ marginLeft: "auto", marginRight: "auto" }}>
                <span className="mt-0 font-weight-bold fw300 filtertxthead">
                  {Language?.general?.filters_applied}{" "}
                  <span
                    onClick={() =>
                      dispatch(setCurrentSideBar(SideBarEnum.Search))
                    }
                  >
                    <img src="/images/filters_icon.svg" className="img-fluid" alt="" />
                  </span>
                </span>
                {Object.entries(filter)
                  .filter(([_, v]) => v)
                  .map(([key, value]) => (

                    <span onClick={() => removeFilter(key)}>
                      {console.log("param ----", key, value)}
                      <button className="btn btn-default filterbutton">
                        {value !== 'Tour' && value !== 'Project'
                          && value !== 'All'
                          && <span className="filter-cross-btn" >
                            x</span>} 
                            {value === '5 star Accomodation' ? '4-5 star accomodation' :
                            value === '2 or 3 stars accommodation' ? '2-3 star accomodation' :
                              value === 'Oceania' ? "Australia" :
                                value === "America" ? 'South America' :
                                  key === 'startDate' || key === 'endDate' ? dayjs(value).format('DD/MM/YYYY') : value}
                      </button>
                    </span>
                  ))}
              </div>
              <PerfectScrollbar className="col-lg-10 maxprojlistscroll" style={{ marginLeft: "auto", marginRight: "auto" }}>
                {/* List group*/}
                <ul className="list-group shadow">
                  {_.sortBy(events, e => e.startDate).map((e) => {
                    let latestDate = null;
                    if (e.includedEventsDates) {
                      latestDate = _.sortBy(e.includedEventsDates, d => d.start).find(item => dayjs().isBefore(item.start) && item?.capacity)
                    }
                    const eventType = getEventType(e);
                    const startDate = dayjs(getLatestBookingDate(e.startDate)).toDate()
                    const endDate = dayjs(getLatestBookingDate(e.startDate)).add(1, 'd').toDate()
                    const basePrice = calculateEventPrice(eventType, 1, e.category, startDate, endDate, e, 0 )
                    const eventName = selectedLan === 'en' ? e.projectName || e.tourName : selectedLan === 'de' ? e.projectName_de || e.tourName_de : selectedLan === 'es' ? e.projectName_es || e.tourName_es : e.projectName || e.tourName
                    return (
                      <ProjectCard
                        id={e._id}
                        projectName={eventName || e.projectName || e.tourName}
                        price={basePrice.price || e.consumerPricePerDay || e.consumerPrice}
                        startDate={latestDate ? dayjs(latestDate.start).toDate() : getLatestBookingDate(e.startDate)}
                        location={e.location}
                        picture={
                          e.projectPictures?.[0]?.imageURL ||
                          e.tourPictures?.[0]?.imageURL
                        }
                        event={e}
                      />
                    );
                  })}
                </ul>
                {/* End */}
              </PerfectScrollbar>
              {/* <div className="col-lg-5 d-flex justify-content-center align-items-center maxprojlisth">
                <img
                  src={
                    location
                      ? `/images/${location.replace(' ', '-')}-${eventType}s.png`
                      : eventType === 'All' ? `/images/World-${switchMap?.toLowerCase()}.png`
                        : `/images/World-${eventType?.toLowerCase()}s.png`
                  }
                  className="img-fluid"
                  alt=""
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}