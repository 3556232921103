import dayjs from "dayjs";
import _, { isEmpty, range } from 'lodash';
import moment from 'moment';
import {
  forwardRef,
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import DatePicker from '../Components/dateRangePicker';
import ImageBox from "../Components/ImageBox";
import { RootState } from "../store";
import {
  addTourItemToCart,
  deleteTourItemFromCart,
  ToursCartItem,
  updateTourItemToCart
} from "../store/Cart";
import {
  setCurrentDayToDayProgram,
  setCurrentSideBar,
  SideBarEnum
} from "../store/SideBar";
import { fetchTourById, Tours } from "../store/Tours";
import { getAccommodationCatgeroyName, getFoodChoiceName, getLatestBookingDate, getReviewArray, translateToEng } from "../util";

import calculateEventPrice from "../utilities/calcProjectPrice";
import { EventDetails } from "./event-details";

export const TourDetails: FunctionComponent = () => {
  const { id } = useParams();
  const tourID = parseInt(id);
  const noOfParticpantsRef = useRef<HTMLSelectElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const [tourDetails, setTourDetails] = useState<Tours | undefined>();
  const [minParticipantRange, setMinParticipantRange] = useState<number>(1);
  const [maxParticipantRange, setMaxParticipantRange] = useState<number>(2);
  const [basePrice, setBasePrice] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [addDiscount, setAddDiscount] = useState(false);
  const [voucherApplied, setVoucherApplied] = useState(false);
  const isLoggedIn = useSelector(
    (state: RootState) => state.authReducer.UserDetails !== undefined
  );
  const userDetails = useSelector((state: RootState) => state.authReducer.UserDetails);
  const macthingCartItem = useSelector((state: RootState) =>
    state.cart.Cart?.items.find((i) => i.tourId === tourID)
  );
  const Language = useSelector((state: RootState) => state.language.language?.content)
  const genralLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content
  );
  const starArray = getReviewArray(2);

  useEffect(() => {
    const fetchData = async () => {
      const tourDetails = await dispatch(fetchTourById(tourID));
      setTourDetails(tourDetails as any);
    };
    fetchData();
  }, []);

  const [noOfParticipant, setNoOfParticipant] = useState(1);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: dayjs().toDate(),
    endDate: dayjs().add(1, "day").toDate(),
  });
  const [accomodation, setAccomodation] = useState("");
  const [choiceOfFood, setChoiceOfFood] = useState("");

  const [voucher, setVoucher] = useState("")
  const [discount, setDiscount] = useState<number>(0)
  const [isDiscountPercentage, setIsDiscountPercentage] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [numberOFNights, setNumberOFNights] = useState(0);
  const [reservationDate, setReservationDate] = useState<Date>(new Date());
  const [includedDates, setIncludedDates] = useState<Array<Date> | undefined>([dayjs().add(6, "d").toDate()]);
  const [selectedReservationDateRange, setSelectedReservationDateRange] = useState({
    start: dayjs().toDate(),
    end: dayjs().add(1, 'd').toDate()
  });

  const getCapacityOfInterval = ({ startDate, endDate }: any) => {
    let Capacity: number | undefined = 1;
    if (tourDetails && tourDetails?.includedEventsDates !== null) {
      for (let item of tourDetails?.includedEventsDates) {
        if (dayjs(item.start).isSame(startDate) && dayjs(item.end).isSame(endDate)) {
          Capacity = item.capacity;
        };
      }
    }
    return Capacity;
  }


  useEffect(() => {
    if (tourDetails) {
      if (isEmpty(tourDetails?.includedEventsDates) || tourDetails?.includedEventsDates === null) return;
      const includedEventsDates: any = tourDetails.includedEventsDates;
      // const currentDate = dayjs().format('YYYY-MM-DD:00:00:00');
      const currentDate = dayjs().add(30, 'days').format('YYYY-MM-DD:00:00:00');
      for (let dateRange of includedEventsDates) {
        const startDate = dayjs(dateRange.start).format('YYYY-MM-DD:00:00:00');
        if (currentDate < startDate && dateRange?.capacity) {
          setReservationDate(dayjs(dateRange.start).toDate());
          setSelectedReservationDateRange({
            start: dayjs(dateRange.start).toDate(),
            end: dayjs(dateRange.end).toDate()
          });
          const capacity: number = getCapacityOfInterval({
            startDate: dayjs(dateRange.start).toDate(),
            endDate: dayjs(dateRange.end).toDate()
          })
          setMaxParticipantRange(capacity);
          break;
        }
      }
    }
  }, [tourDetails])



  useEffect(() => {
    if (tourDetails) {
      setAccomodation(tourDetails.category);
      setChoiceOfFood(tourDetails.choiceOfFood);
      // const startDate = dayjs(getLatestBookingDate(tourDetails.startDate)).toDate();
      // const endDate = dayjs(getLatestBookingDate(tourDetails.startDate)).add(1, 'd').toDate();
      const startDate = dayjs(getLatestBookingDate(selectedReservationDateRange.start)).toDate();
      const endDate = dayjs(getLatestBookingDate(selectedReservationDateRange.end)).toDate();
      // setSelectedDateRange({
      //   startDate: dayjs(selectedReservationDateRange.start).toDate(),
      //   endDate: dayjs(selectedReservationDateRange.end).toDate()
      // })
      setSelectedDateRange({ startDate, endDate })
      let { price } = calculateEventPrice('tour', 1, tourDetails.category, startDate, endDate, tourDetails, 0)
      setBasePrice(price);
    }
  }, [tourDetails, selectedReservationDateRange]);

  useEffect(() => {
    if (tourDetails) {
      const nowDate = moment();
      const startDate = moment(selectedDateRange.startDate);
      const daysToStart = startDate.diff(nowDate, 'days');
      const capacity = getCapacityOfInterval({ startDate: selectedDateRange.startDate, endDate: selectedDateRange.endDate });
      if (daysToStart < tourDetails.noticePeriod && tourDetails.minCapacity) {
        let remainingParticipantToConfirmBooking = 0;
        if (capacity !== 0) { //* interval still has some capacity to be booked //
          const minCapacity = tourDetails.minCapacity - (tourDetails.capacity - capacity);
          if (minCapacity === 0) { //* if minCapacity is reached then set min select option to 1 //
            remainingParticipantToConfirmBooking = 1;
          } else if (minCapacity < tourDetails.minCapacity) {
            remainingParticipantToConfirmBooking = 1
          }
          else { //* if minCapacity is not reached // then set min select option to minCapacity //
            remainingParticipantToConfirmBooking = minCapacity;
          }
        };

        setMinParticipantRange(remainingParticipantToConfirmBooking);
        setNoOfParticipant(remainingParticipantToConfirmBooking);
      }
      else {
        setMinParticipantRange(1);
        setNoOfParticipant(1);
      }
      setMaxParticipantRange(capacity)
    }
  }, [tourDetails, selectedDateRange]);

  useEffect(() => {
    if (!tourDetails) return
    const v = tourDetails.vouchers.find(item => item.voucher.toLocaleLowerCase() === voucher.toLocaleLowerCase())
    setDiscount(Number(v?.discountValue))
    setIsDiscountPercentage(v?.isPercentageDiscount || false);
    setVoucherApplied(false);
  }, [voucher, tourDetails])

  useEffect(() => {
    if (!tourDetails) return;

  }, [selectedDateRange]);

  useEffect(() => {
    if (tourDetails) {
      const { startDate, endDate } = selectedDateRange;
      const { numberOfNights, price } = calculateEventPrice(
        'tour',
        noOfParticipant,
        accomodation,
        startDate,
        endDate,
        tourDetails,
        0,
        choiceOfFood,
      );
      setTotalPrice(price);
      setVoucherApplied(false);
      setNumberOFNights(numberOfNights);
    }
  }, [tourDetails, accomodation, noOfParticipant, selectedDateRange, choiceOfFood, discount]);

  useEffect(() => {
    if (tourDetails) {
      const _CalculateDateRangeToInclude = () => {
        // const currentDate = dayjs().format('YYYY-MM-DD:00:00:00');
        const currentDate = dayjs().add(30, 'days').format('YYYY-MM-DD:00:00:00');
        const dates = tourDetails.includedEventsDates;
        if (isEmpty(dates)) return [];
        let includedDates = []
        for (let item of dates) {
          const { start, capacity } = item;
          const startDate = dayjs(start).format('YYYY-MM-DD:00:00:00');
          if ((currentDate < startDate) && capacity !== 0) { //* if current date is less than start date of the interval, then we can book else event is started //

            includedDates.push(dayjs(start).toDate());
          }
          setIncludedDates([...includedDates]);
        }
      }
      _CalculateDateRangeToInclude();


    }
  }, [tourDetails]);




  if (!tourDetails) {
    return null;
  }
  const choiceOfFoodOptions = [
    {
      text: tourDetails?.choiceOfFood,
      value: tourDetails?.choiceOfFood
    },

    ...tourDetails.extraChoiceOfFood?.map(acc => ({ text: acc.category, value: acc.category }))
  ]
  const accommodationOptions = [
    {
      text: tourDetails.category,
      value: tourDetails.category,
    },
    ...tourDetails.extraAccommodation.map((acc) => ({
      text: acc.category,
      value: acc.category,
    })),
  ];

  const {
    tourPictures,
    accommodationPicture,
    transportationPicture,
  } = tourDetails;
  const allPictures = [
    ...tourPictures,
    ...accommodationPicture,
    ...transportationPicture,
  ];

  const accomodationDesc =
    tourDetails.extraAccommodation.find(
      (eAcc) => eAcc.category === accomodation
    )?.description || tourDetails.accommodationDescription;

  const getCartItem = () => {
    const { start, end } = selectedReservationDateRange;
    const cartItem: ToursCartItem = {
      tourId: tourDetails.id,
      tourOwnerId: "",
      tourName: tourDetails.tourName,
      tourDescription: tourDetails.tourDescription,
      tourImage: tourDetails.accommodationPicture[0],
      price: totalPrice,
      // startDate: startDate,
      // endDate: endDate,
      startDate: dayjs(start).toDate(),
      endDate: dayjs(end).toDate(),
      numberOfNights: numberOFNights,
      participants: String(noOfParticipant),
      localSupervisorPhone: tourDetails.localSupervisorPhone,
      contactEmail: tourDetails.contactEmail,
      localSupervisorName: tourDetails.localSupervisorName,
      accommodation: accomodation,
      travelSafetyUrl: tourDetails.location,
      levelOfDifficulty: tourDetails.levelOfDifficulty,
      natureOfEvent: tourDetails.natureOfEvent,
      natureOfEventdescription: tourDetails.natureOfEventdescription,
      nearAirport: tourDetails.nearAirport,
      choiceOfFood,
      additionalPrice: tourDetails.additionalPrice,
      spokenLanguages: tourDetails.spokenLanguages,
      voucherCode: voucher,
      discountApplied: discount && voucherApplied ? true : false,
      actualPrice: discount ? Number(totalPrice + discount) : totalPrice,
    };
    return cartItem;
  };
  const addToCart = async (isBookNow: boolean = false) => {

    if (isLoggedIn === false) {
      dispatch(setCurrentSideBar(SideBarEnum.Login));
    }
    if (userDetails?.swt_role === 'provider') {
      return toast.error("You are logged in as a provider. You need to create a normal user account to be able to make a booking with Small World Travel")
    }
    const cartItem: ToursCartItem = getCartItem();
    await dispatch(addTourItemToCart(cartItem));
    if (isBookNow) {
      history.push("/cart");
    } else {
      dispatch(setCurrentSideBar(SideBarEnum.Cart));
    }
  };

  const removeFromCart = async () => {
    await dispatch(deleteTourItemFromCart(tourID));
    dispatch(setCurrentSideBar(SideBarEnum.Cart))
  };

  const updateCart = async () => {

    const cartItem: ToursCartItem = getCartItem();
    await dispatch(updateTourItemToCart(cartItem));
    dispatch(setCurrentSideBar(SideBarEnum.Cart))
  };

  const onDaytoDayProgram = () => {
    // dispatch(setCurrentDayToDayProgram(tourDetails))
  };

  const bookNowCLick = () => {
    noOfParticpantsRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    noOfParticpantsRef.current?.focus();
  };

  const handleDiscount = () => {
    setVoucherApplied(true);
    if (isDiscountPercentage) {
      const discountValue = (totalPrice * discount) / 100;
      const finalAmount = totalPrice - discountValue;
      setTotalPrice(Math.round(finalAmount));
    } else setTotalPrice(totalPrice - (discount ? discount : 0));
  }

  let latestDate = null;
  if (tourDetails.includedEventsDates) {
    latestDate = _.sortBy(tourDetails.includedEventsDates, d => d.start).find(item => dayjs().add(0, 'd').isBefore(item.start) && item.capacity)
  }
  return (
    <div className="container">
            {showModal && 
      <Modal show={showModal ? true : false} onHide={() => setShowModal(false)} className="day-to-day-modal m-5">
        <div
          id="btn-close-modal"
          className="close-animatedModalReadMore closespan"
          onClick={() => setShowModal(false)}
        >
          <div className="crossmarkx mr-3">x</div>
        </div>
        <EventDetails fromModal={true} />  
      </Modal>}
      <div className="row projsecpaddup">
        <div className="col-12">
          <ImageBox Images={allPictures} />
        </div>
      </div>
      <div className="row com-font">
        <div className="col-md-12 col-lg-12">
          <div className="row">
            <div className="col-lg-6">
              <div className="swt-headmain com-font text-uppercase">
                {tourDetails.tourName}
              </div>
              <div className="readmorelink">
                <button onClick={() => setShowModal(true)} className="btn text-dark p-0 btn-link text-sm">{`${Language?.general?.read_more}`}</button>
              </div>
            </div>
            <div className="col-lg-6">
              <a
                href="javascript:void(0)"
                onClick={bookNowCLick}
                className="btn btn-sm btnsmbook float-right"
              >
                {Language?.general?.book_now}
              </a>
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 d-none">
                  {/* <button class="circleplus circleplus-color btn btn-sm"><i class="fa fa-plus"
            aria-hidden="true"></i></button> */}
                  <button className="btn herrticon btn-sm">
                    <i className="far fa-heart" />
                  </button>
                  <div className="row projectBooking">
                    <div className="col-lg-12 rate">
                      <div className="star-ratingproj text-left">
                        {starArray.map((checked, i) => (
                          <span
                            className={`fa fa-star ${checked ? "checked" : ""}`}
                            key={i}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="col-lg-12 proj-detail-text com-font">
                      {Language?.general?.customer_reviews}

                    </div>
                    <div className="col-md-12">
                      <a
                        href="javascript:void(0)"
                        onClick={onDaytoDayProgram}
                        className="btn-link"
                      >
                        <img src="/images/leaflet_icon.svg" alt="" />
                        <span className="daytxt">Day-to-day program</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 float-right text-right">
                  <img src="/images/suv-car.svg" className="img-fluid" alt="" />
                  <span className="datetxt" style={{ fontSize: "1.3em" }}>
                    {latestDate && dayjs(latestDate.start).format("D")}
                  </span>
                  <span className="monthtxt">
                    {latestDate && dayjs(latestDate.start).format("MMMM")}
                  </span>
                  <span className="posplaneic">
                    <p className="daytxt mt-2 mb-0">{tourDetails.duration} days</p>
                    <p className="mt-2 mb-0 loctxt">
                      <img src="/images/blackmap.svg" className="img-fluid" alt="" />{" "}
                      {tourDetails.location}
                    </p>
                    <div className="mt-1">
                      <div className="font-weight-bold my-2 pricetxt">
                        {Language?.general?.from}  {basePrice}€
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="details"></div>
      <div className="row paddformupdwn">
        <div className="col-md-6 col-lg-6 ">
          <div className="swt-headmain com-font text-uppercase bluetext">
            {Language?.general?.select} {Language?.general?.your} {Language?.general?.preferences}
          </div>
          <div className="row textimgprojpaddud com-font">
            <div className="col-lg-12 proj-detail-text text-justify">
              <form id="projectBooking">
                <div className="form-group">
                  <label htmlFor="datePicker" className="text-dark">{Language?.general?.select} {Language?.general?.date}</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend icon-with-select">
                      <img className="input-group-text input-group-icon p-2" src="/images/calendar_icon.svg" alt="calendar-icon" />
                    </div>
                    <DatePicker
                      tourCapacity={tourDetails.capacity}
                      datesToInclude={tourDetails?.includedEventsDates}
                      reservationDate={reservationDate}
                      selectedReservationDateRange={selectedReservationDateRange}
                      setReservationDate={setReservationDate}
                      setSelectedReservationDateRange={setSelectedReservationDateRange}
                      includedDates={includedDates}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="selectPartipants" className="text-dark">{Language?.general?.select} {Language?.general?.participant}</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <div className="input-group-prepend icon-with-select">
                        <img className="input-group-text input-group-icon p-2" src="/images/user_icon.svg" alt="user-icon" />
                      </div>
                    </div>
                    <select
                      ref={noOfParticpantsRef}
                      className="form-control input-group-border-let-none"
                      id="selectPartipants"
                      aria-describedby="basic-addon1"
                      value={noOfParticipant}
                      onChange={(e) =>
                        setNoOfParticipant(parseInt(e.target.value))
                      }
                    >
                      {
                        //  range(initialParticipantRange, (tourDetails?.capacity - tourDetails?.numberOfReservations)+1).map((i, index) => (
                        range(minParticipantRange, (maxParticipantRange === tourDetails.capacity ? maxParticipantRange :
                          maxParticipantRange) + 1).map((i, index) => (
                            <option key={index} value={i.toString()}>{i}</option>
                          ))}

                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="selectcategory" className="text-dark"> {Language?.general?.accomodation_categories}</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend icon-with-select">
                      <img className="input-group-text input-group-icon p-2" src="/images/accomodation_icon.svg" alt="bed-icon" />
                    </div>
                    <select
                      className="form-control input-group-border-let-none"
                      id="selectcategory"
                      aria-describedby="category"
                      value={accomodation}
                      onChange={(e) => setAccomodation(e.target.value)}
                    >
                      {accommodationOptions.map((acc, i) => (
                        <option value={translateToEng(acc.value)}>{getAccommodationCatgeroyName(acc.text)}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="selectchoiceOfFood" className="text-dark">
                    {Language?.project?.choice_of_food}
                  </label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend icon-with-select">
                      <img className="input-group-text input-group-icon p-2" src="/images/meal_icon.svg" alt="meal-icon" />
                    </div>
                    <select
                      className="form-control input-group-border-let-none"
                      id="choiceOfFood"
                      aria-describedby="choiceOfFood"
                      value={choiceOfFood}
                      onChange={(e) => setChoiceOfFood(e.target.value)}
                    >
                      {choiceOfFoodOptions?.map((acc, i) => (
                        <option value={translateToEng(acc.value)}>{getFoodChoiceName(acc.text)}</option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* <div className="form-group">
                  <label htmlFor="selectwishes">Personal wishes</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text input-group-icon"
                        id="wishes"
                      >
                        <i className="fas fa-circle-notch" />
                      </span>
                    </div>
                    <input
                      className="form-control input-group-border-let-none"
                      id="selectwishes"
                      aria-describedby="wishes"
                      value={personalWishes}
                      onChange={(e) => setPersonalWishes(e.target.value)}
                    />
                  </div>
                </div> */}

                <div className="form-group">
                  {!addDiscount && <u className="accopentxt" onClick={() => setAddDiscount(true)}>{Language?.general?.add} {Language?.general?.discount} {`${Language?.general?.code}?`}</u>}
                  {addDiscount && tourDetails.vouchers?.length &&
                    <div className="mb-3">
                      <label className="text-dark">
                        {Language?.general?.discount} {Language?.general?.code}:
                      </label>
                      <div className="d-flex">
                        <div className="input-group mr-3">
                          <div className="input-group-prepend icon-with-select">
                            <img className="input-group-text input-group-icon p-2" src="/images/voucher_icon.svg" alt="meal-icon" />
                          </div>
                          <input type="text" className="form-control icon-with-select input-group-border-let-none" placeholder={`${Language?.general?.discount_place}`} onChange={(e) => setVoucher(e.target.value)} />
                        </div>
                        <button
                            onClick={() => handleDiscount()}
                            className="btn btn-default btnSaveandStart com-font w-25"
                            type="button"
                            disabled={voucherApplied}
                        >
                          {Language?.general?.apply || 'Apply'}
                        </button>
                      </div>
                      {voucherApplied && !discount && <div className="text-sm text-muted mt-1">{Language?.general?.discount_place_err}</div>}
                    </div>
                    }
                  <div className="text-center swt-headmain com-font text-red fontWeight700">
                    <strong>{totalPrice}€</strong>
                  </div>
                  <div className="text-center swt-headmain com-font text-red">
                    {Language?.general?.total} {Language?.general?.price}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      {macthingCartItem ? (
                        <button
                          onClick={removeFromCart}
                          className="btn btn-sm btn-default btnfinalbook btn-block compaddbtns btn-danger"
                          type="button"
                          disabled={userDetails?.swt_role === 'provider' ? true : false}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>{" "}
                          {Language?.general?.remove} {Language?.general?.from} {Language?.general?.cart}

                        </button>
                      ) : (
                        <button
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Provider cannot book any tour"
                          onClick={() => addToCart(false)}
                          className="btn btn-sm btn-default btnviewproj btn-block compaddbtns"
                          type="button"
                          disabled={userDetails?.swt_role === 'provider' || !maxParticipantRange ? true : false}
                        >
                          {Language?.general?.save_to_mycart}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      {!maxParticipantRange ? <button
                        onClick={updateCart}
                        className="btn btn-sm btn-default btnviewproj btn-block compaddbtns"
                        type="button"
                        disabled={userDetails?.swt_role === 'provider' || !maxParticipantRange ? true : false}
                        title='Houseful! Please select another date for booking'
                      >
                        {Language?.general?.reserved || "Reserved"}
                      </button> :
                        macthingCartItem ? (
                          <button
                            onClick={updateCart}
                            className="btn btn-sm btn-default btnviewproj btn-block compaddbtns"
                            type="button"
                            disabled={userDetails?.swt_role === 'provider' ? true : false}
                          >
                            {Language?.general?.update} {Language?.general?.cart}
                          </button>
                        ) : (
                          <button
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Provider cannot book any tour"
                            onClick={() => addToCart(true)}
                            className="btn btn-default btn-block btnfinalbook com-font"
                            type="button"
                            disabled={
                              userDetails?.swt_role === 'provider' ? true : false}
                          >
                            {Language?.general?.book_now}
                          </button>
                        )}

                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6">
          <div className="swt-headmain com-font text-uppercase bluetext">
            {Language?.general?.choosen_tour_details}
          </div>
          <div className="col">
            <label className="text-dark">
              {Language?.general?.risk_level}<span className="text-danger">*</span>
            </label>
            <div id="riskLevel" className="mb-3">
              <ProgressBar>
                {tourDetails?.levelOfDifficulty === 'easy' && <ProgressBar style={{ backgroundColor: '#A3A3A3' }} now={25} label={'Easy'} />}
                {tourDetails?.levelOfDifficulty === 'moderate' && <ProgressBar style={{ backgroundColor: '#5AFF15' }} now={50} label={'Moderate'} />}
                {tourDetails?.levelOfDifficulty === 'difficult/ risky' && <ProgressBar style={{ backgroundColor: '#6A66A3' }} now={75} label={"Difficult"} />}
                {tourDetails?.levelOfDifficulty === 'extreme' && <ProgressBar style={{ backgroundColor: '#F52F57' }} now={100} label={'Extreme'} />}
              </ProgressBar>
            </div>
            <Tooltip anchorSelect="#riskLevel" content={Language?.general?.risk_level_helper} />
            {/* <p className="text-muted">
              <span className="text-danger ">*</span>
              {Language?.general?.risk_level_helper}
            </p> */}
          </div>
          <div className="row textimgprojpaddud com-font px-4">
            <div className="col-lg-12 proj-detail-text text-justify text-dark">
              <ul className="p-0">
                <li>{noOfParticipant} {Language?.general?.person}</li>
                {selectedDateRange && (
                  <li>
                    {/* {dayjs(selectedDateRange.startDate).format("DD.MM.YYYY")} -{" "}
                    {dayjs(selectedDateRange.endDate).format("DD.MM.YYYY")} */}
                    {dayjs(selectedReservationDateRange.start).format("DD.MM.YYYY")} -{" "}
                    {dayjs(selectedReservationDateRange.end).format("DD.MM.YYYY")}
                  </li>
                )}
                <li>{numberOFNights} {Language?.general?.nights}</li>
                <li>{Language?.general?.type_of} {Language?.general?.tour}: {tourDetails.natureOfEvent} </li>
                <li>{Language?.tour?.near_airport}: {tourDetails.nearAirport} </li>
                <li> {Language?.tour?.spoken_languages}: {tourDetails.spokenLanguages} </li>
                <li>
                  {Language?.tour?.additional_cost}:{' '}

                  {tourDetails.additionalPrice}
                </li>
                {/* <li> */}
                  <li>
                    {choiceOfFood === '3 times a day meals' || choiceOfFood === 'three-times-a-day meals provided' ? "3 times a day meal - meals cooked from regional vegetables" : "snacks/water - snacks & fresh drinking water"}
                  </li>
                  <li>
                    {!accomodation?.includes('accommodation') ? accomodation?.concat(' accommodation') : accomodation}*
                    <ul style={{ paddingLeft: "0px" }} className="list-none ">

                      {Language?.tour?.standards[accomodation]?.map((item: any) =>
                        <li>
                          <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"} style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                          {item.value}
                        </li>
                      )}
                    </ul>

                  </li>

                {/* </li> */}
                {/* <li>Food preference:{personalWishes}</li> */}
              </ul>
              <div className="includeprojtxt">{Language?.general?.included_standards}</div>
              {Language?.general?.tourStandards?.map((item: any) =>
                <li>
                  <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"} style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                  {item.text}
                </li>
              )}
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};
