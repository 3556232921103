import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { fetchDocumentContent } from "../store/Documents";
import { fetchFooterContent } from "../store/Footer";
import { setCurrentSideBar, SideBarEnum } from "../store/SideBar";
import WorkWithUs from "./WorkWithUs";
import { Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

export default function Footer() {
  const dispatch = useDispatch();
  const role = useSelector(
    (rootState: RootState) => rootState.authReducer.UserDetails?.swt_role
  );

  const genralLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content?.general!
  );
  const appLanguage = useSelector(
    (rootState: RootState) => rootState.language?.language?.language
  );

  const documentContent = useSelector(
    (rootState: RootState) => rootState.documents.documentContent
  );

  useEffect(() => {
    const language = appLanguage ? appLanguage : "en";
    dispatch(fetchFooterContent());
    dispatch(fetchDocumentContent(language));
  }, [appLanguage]);

  const openContactSideBar = () =>
    dispatch(setCurrentSideBar(SideBarEnum.Contact));

  const isMobileView = useMediaQuery({ maxWidth: 991 });

  const footerLogoAlign = isMobileView
    ? "justify-content-center"
    : "justify-content-left";

  return (
    <>
      {/* Add all page content inside this div if you want the side nav to push page content to the right (not used if you only want the sidenav to sit on top of the page */}
      {!["provider", "admin"].includes(role!) && <WorkWithUs />}
      <section id="footer">
        <div
          className="container-fluid com-font"
          style={{
            maxWidth: "1800px",
          }}
        >
          <div className="row">
            <div
              className={`col-lg-12 d-flex ${footerLogoAlign} align-items-center`}
            >
              <img alt="" src="/images/footer_logo.svg" className="img-fluid" />
            </div>
          </div>
          <div
            className={`row ${isMobileView ? "text-center" : ""} paddfootlink`}
          >
            <FooterColumn
              title={genralLan?.about_us}
              iconSrc="/images/about-footer-ic.svg"
              items={[
                { text: genralLan?.about, link: "/about-us" },
                { text: genralLan?.faq, link: "/faqs" },
              ]}
            />

            <FooterColumn
              title={genralLan?.legal}
              iconSrc="/images/legal-ic.svg"
              items={[
                {
                  text: genralLan?.terms_and_conditions,
                  link: documentContent?.terms_and_conditions?.data?.attributes
                    ?.url,
                  openInNewTab: true,
                },
                {
                  text: genralLan?.privacy_policy,
                  link: documentContent?.privacy_policy?.data?.attributes?.url,
                  openInNewTab: true,
                },
                { text: genralLan?.imprint, link: "/imprint" },
              ]}
            />

            <FooterColumn
              title={genralLan?.contact_us}
              iconSrc="/images/email_footer.svg"
              onClick={openContactSideBar}
              items={[
                {
                  text: "Small World Travel GmbH",
                  link: "#",
                },
                { text: "+49 (0)176 6655 0020", link: "#" },
                { text: "info@socialtravel.world", link: "#" },
              ]}
              isContactUs
            />

            {!isMobileView && <FooterSocial vertical />}
          </div>

          {isMobileView && <FooterSocial />}

          <div className="row mt-3">
            <div className="col-xs-12 col-sm-12 col-md-12  text-center text-white">
              <div className="small">
                © {genralLan?.all_rights_reserved} Small World Travel GMBH
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const FooterLinkHead = ({
  title,
  iconSrc,
  onClick = () => {},
}: {
  title: string;
  iconSrc: string;
  onClick?: () => void;
}) => {
  const isMobileView = useMediaQuery({ maxWidth: 991 });

  return (
    <div
      className={
        isMobileView
          ? "footerlinkhead text-capitalize mb-2 d-flex justify-content-center align-items-center"
          : "footerlinkhead text-capitalize mb-2 d-flex"
      }
    >
      <div className="d-flex">
        <img
          alt=""
          onClick={onClick}
          src={iconSrc}
          className="img-fluid footicsp mr-2"
        />
        <span>{title}</span>
      </div>
    </div>
  );
};

type FooterColumnProps = {
  title: string;
  iconSrc: string;
  onClick?: () => void;
  items: { link: string; text: string; openInNewTab?: boolean }[];
  isContactUs?: boolean;
};
const FooterColumn = ({
  title,
  iconSrc,
  onClick,
  items,
  isContactUs = false,
}: FooterColumnProps) => {
  const columnSize = isContactUs ? 12 : 6;
  const isMobileView = useMediaQuery({ maxWidth: 991 });
  const columnItemStyle = isMobileView
    ? { marginLeft: "0rem" }
    : { marginLeft: "2rem" };

  return (
    <Col
      xs={columnSize}
      sm={columnSize}
      md={columnSize}
      lg={isContactUs ? 3 : 4}
      xl={isContactUs ? 3 : 4}
    >
      <FooterLinkHead title={title} iconSrc={iconSrc} onClick={onClick} />
      <ul className="list-unstyled quick-links contact-txtsm">
        {items.map((item: any) => {
          const target = item.openInNewTab ? "_blank" : "_self";
          const rel = item.openInNewTab ? "noreferrer" : "";
          const textCapitalize = item?.text?.includes("@")
            ? ""
            : "text-capitalize";

          return (
            <li className={textCapitalize} style={columnItemStyle}>
              <a target={target} rel={rel} href={item.link}>
                {item.text}
              </a>
            </li>
          );
        })}
      </ul>
    </Col>
  );
};

const SOCIAL_MEDIA = [
  {
    name: "facebook",
    icon: "/images/facebook_white.svg",
    link: "https://www.facebook.com/socialtravel.world1",
  },
  {
    name: "instagram",
    icon: "/images/instagram.svg",
    link: "https://www.instagram.com/socialtravel_world",
  },
  {
    name: "linkedin",
    icon: "/images/linkedin.svg",
    link: "https://www.linkedin.com/in/corinna-klus/",
  },
];

type FooterSocialProps = {
  sidebar?: boolean;
  vertical?: boolean;
};
export const FooterSocial = ({
  sidebar = false,
  vertical = false,
}: FooterSocialProps) => {
  const footerStyle = sidebar ? "" : "text-center mb-4";

  if (vertical) {
    return (
      <div className={`col-xs-1 col-sm-1 col-md-1 ${footerStyle}`}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "right",
          }}
        >
          {SOCIAL_MEDIA.map((item) => (
            <a
              key={item.name}
              href={item.link}
              rel="noreferrer"
              className="text-light ic-2x m-2"
              target={"_blank"}
            >
              <img
                alt={item.name}
                src={item.icon}
                className="img-fluid socialft"
              />
            </a>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className={`col-xs-12 col-sm-12 col-md-12 ${footerStyle}`}>
        {SOCIAL_MEDIA.map((item) => (
          <a
            key={item.name}
            href={item.link}
            rel="noreferrer"
            className="text-light ic-2x m-2"
            target={"_blank"}
          >
            <img
              alt={item.name}
              src={item.icon}
              className="img-fluid socialft"
            />
          </a>
        ))}
      </div>
    </div>
  );
};
