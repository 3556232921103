import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import range from "lodash/range";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import { RootState } from "../../store";
import {
  CloudinaryImg,
  ExtraAccommodation,
  ExtraChoiceOfFood,
  IncludedDateRangeObject,
  RecurrenceSelection,
  Tours,
} from "../../store/Tours/type";
import Countries from "../../utilities/countries";
import Calendar from "../Calendar2";
import { getDifferenceInDays } from "../Calendar2/helpers";
import FileUpload from "../FileUpload";
import { AutoComplete } from "../autoComplete";
import { Tooltip } from "react-tooltip";
import { saveEventAsDraft } from "../../store/Drafts";
import { toast } from "react-toastify";
import { translateToEng } from "../../util";

const tourSchema = yup.object().shape({
  available: yup.boolean(),
  customId: yup.string(),
  tourName: yup.string().required(),
  // contactEmail: yup.string().required(),
  tourOwnerName: yup.string().required(),
  ownerDescription: yup.string().required(),
  tourDescription: yup.string().required(),
  category: yup.string().required(),
  localSupervisorName: yup.string().required(),
  localSupervisorPhone: yup.number().required(),
  location: yup.string().required(),
  startDate: yup.date().when([], {
    is: (val: any) => {
      return val === "" || val === undefined || val === null
    },
    then: yup.date().required(),
    otherwise: yup.date().notRequired(),
  }),
  endDate: yup.date().when([], {
    is: (val: any) => val === "" || val === undefined || val === null,
    then: yup.date().required(),
    otherwise: yup.date().notRequired()
  }),
  capacity: yup.number().required(),
  minCapacity: yup.number().required(),
  price: yup.number().required(),
  additionalPrice: yup.string(),
  accommodationDescription: yup.string().required(),
  foodDescription: yup.string().required(),
  transportation: yup.string().required(),
  additionalInfo: yup.string().required(),
  // eventCity: yup.string().required(),
  dayToDay: yup.array().of(
    yup.object().shape({
      value: yup.string().required(),
      city: yup.string().required()
    })
  ).required(),
  extraAccommodation: yup.array().of(
    yup.object().shape({
      category: yup.string().required(),
      extraPrice: yup.number().required(),
      description: yup.string().required(),
    })
  ),
  noticePeriod: yup.number().required(),
  duration: yup.number().required(),
  feedback: yup.array().of(
    yup.object().shape({
      value: yup.string(),
    })
  ),
  levelOfDifficulty: yup.string().required(),
  nearAirport: yup.string().required(),
  choiceOfFood: yup.string().required(),
  foodPrice: yup.number().default(0).required(),
  spokenLanguages: yup.string().required(),
  extraChoiceOfFood: yup.array().of(
    yup.object().shape({
      category: yup.string().required(),
      description: yup.string().required(),
    })
  ),
  natureOfEvent: yup.string().required(),
  natureOfEventdescription: yup.string().required(),
  airportCost: yup.number().required(),
  sdg: yup.string().required(),
});

interface TourFormProps {
  initialData?: Tours;
  onSubmit: (tours: Tours, isDraft: boolean) => void;
  isProviderForm?: boolean;
  status?: boolean;
  onSave?: (data: any, shoudSaveDraft: boolean) => void;
  onPrev?: () => void;
  onStartOver?: () => void;
  hidePrev?: boolean;
  isDraft?: boolean
  hasReservations?: boolean
}

interface PicError {
  field: string;
  error: boolean;
}

const ToursForm: FunctionComponent<TourFormProps> = ({
  initialData,
  onSubmit,
  isProviderForm,
  status,
  onSave,
  onPrev,
  onStartOver,
  hidePrev,
  isDraft,
  hasReservations = false
}) => {
  const location = useLocation();
  const isUpdateMode = location.pathname !== "/profile/tours/add";

  const userDetails = useSelector(
    (rootState: RootState) => rootState.authReducer.UserDetails!
  );
  const tourLan = useSelector(
    (rootState: RootState) => rootState.language.language?.content?.tour!
  );
  const Language = useSelector(
    (rootState: RootState) => rootState.language.language?.content!
  );
  const [tourPictures, setTourPictures] = useState<CloudinaryImg[]>(
    initialData?.tourPictures || []
  );
  const [tourOwnerPicture, setTourOwnerPicture] = useState<CloudinaryImg[]>(
    initialData?.tourOwnerPicture || []
  );
  const [accommodationPicture, setAccommodationPicture] = useState<
    CloudinaryImg[]
  >(initialData?.accommodationPicture || []);
  const [transportationPicture, setTransportationPicture] = useState<
    CloudinaryImg[]
  >(initialData?.transportationPicture || []);

  const [pictureErrors, setPictureErrors] = useState<PicError[]>([
    { field: "tourPictures", error: false },
    { field: "accommodationPicture", error: false },
    { field: "tourOwnerPicture", error: false },
    { field: "transportationPicture", error: false },
  ]);

  const [showStandards, setShowStandards] = useState<string | undefined>();
  const [showCalendarModal, setShowCalendarModal] = useState<boolean>(false);
  const [showCalendarModalError, setShowCalendarModalError] = useState<boolean>(false);
  const [draftClicked, setDraftClicked] = useState<boolean>(false);
  const [calendarDateRange, setCalendarDateRange] = useState<Array<Date>>([
    dayjs().add(1, "d").toDate(),
    dayjs().add(2, "d").toDate(),
  ]);
  const [includedDates, setIncludedDates] = useState<
    IncludedDateRangeObject[] | []
  >([]);
  const [minDate, setMinDate] = useState(
    dayjs().add(1, "d").format("YYYY-MM-DD")
  );
  const [recurrenceSelection, setRecurrenceSelection] = useState<RecurrenceSelection>();

  const dispatch = useDispatch();
  const history = useHistory()

  const getDefaultValues = () => {
    if (initialData) {
      if ("startDate" in initialData && "endDate" in initialData) {
        return {
          ...initialData,
          startDate: dayjs(initialData.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(initialData.endDate).format("YYYY-MM-DD"),
          dayToDay: initialData.dayToDay?.map((v) => ({ value: v.value, city: v.city })) || [],
          feedback:
            initialData.feedback?.map((v) => ({ value: v.value })) || [],
        };
      }
      return {
        startDate: dayjs().add(1, "d").format("YYYY-MM-DD"),
        endDate: dayjs().add(2, "d").format("YYYY-MM-DD"),
      };
    } else {
      return {
        startDate: dayjs().add(1, "d").format("YYYY-MM-DD"),
        endDate: dayjs().add(2, "d").format("YYYY-MM-DD"),
      };
    }
  };

  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    getValues,
    setValue,
    trigger,
  } = useForm<Omit<Tours, "dayToDay"> & { dayToDay: { value: string, city: string }[] }>({
    reValidateMode: "onChange",
    resolver: yupResolver(tourSchema),
    // defaultValues: getDefaultValues(),
    defaultValues: initialData ? 
    {
      ...initialData,
      startDate: dayjs(initialData.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(initialData.endDate).format("YYYY-MM-DD"),
      extraAccommodation: initialData?.extraAccommodation || [],
      dayToDay: initialData.dayToDay?.map((v) => ({ value: v.value, city: v.city })) || [],
      feedback:
        initialData.feedback?.map((v) => ({ value: v.value })) || [],
    }: {

    },
  });
  const {
    fields: extraAccommodationFields,
    append: appendExtraAccomodation,
    remove: removeExtraAccomodation,
  } = useFieldArray<ExtraAccommodation>({
    control,
    name: "extraAccommodation",
  });
  const {
    fields: extraChoiceOfFoodFields,
    append: appendExtraChoiceOfFood,
    remove: removeextraChoiceOfFood,
  } = useFieldArray<ExtraChoiceOfFood>({
    control,
    name: "extraChoiceOfFood",
  });
  const {
    fields: dayToDayFields,
    append: appenddayToDay,
    remove: removedayToDay,
  } = useFieldArray<String>({
    control,
    name: "dayToDay",
  });

  const {
    fields: feedbackFields,
    append: appendFeedback,
    remove: removeFeedback,
  } = useFieldArray<String>({
    control,
    name: "feedback",
  });

  const defaultAccomadation = watch("category");
  const choiceOfFood = watch('choiceOfFood')
  const extraChoiceOfFood = watch('extraChoiceOfFood')
  const extraAccommodation = watch("extraAccommodation");
  const startDate = watch("startDate");
  const endDate = watch("endDate");

  useEffect(() => {
    if (initialData) {
      if (initialData?.includedEventsDates?.length) {

        const tempInclude = initialData.includedEventsDates?.map(item => {
          return {
            start: dayjs(item.start).format('YYYY-MM-DD'),
            end: dayjs(item.end).format('YYYY-MM-DD'),
            capacity: item.capacity,
            confirm: item.confirm,
          }
        })
        setIncludedDates(tempInclude)
      } else {

        setIncludedDates([
          {
            start: dayjs(getValues("startDate")).format('YYYY-MM-DD'),
            end: dayjs(getValues("endDate")).format('YYYY-MM-DD'),
            capacity: getValues("capacity"),
            confirm: false,
          },
        ]);
      }
    } else {
      setIncludedDates([{
        start: dayjs(getValues("startDate")).format('YYYY-MM-DD'),
        end: dayjs(getValues("endDate")).format('YYYY-MM-DD'),
        capacity: getValues("capacity"),
        confirm: false,
      }])
    }

    if (isUpdateMode) {
      recalculateIncludedDates(dayjs(endDate).toDate());
    }

    if (initialData && "startDate" in initialData && "endDate" in initialData) return;
    
    const duration = getDifferenceInDays(
      dayjs(startDate).toDate(),
      dayjs(endDate).toDate()
    );

    setValue("duration", (duration + 1).toString(), { shouldValidate: true });
    setMinDate(
      dayjs(startDate).add(1, "day").format("YYYY-MM-DD")
    );

  }, [startDate, endDate]);

  useEffect(() => {
    if (initialData?.recurrenceSelection) setRecurrenceSelection(initialData?.recurrenceSelection)
  }, []);

  // useEffect(() => {
  //   console.log("Minhal", includedDates)
  // }, [includedDates]);
  useEffect(() => {
    console.log("Minhal", recurrenceSelection)
  }, [recurrenceSelection]);

  const recalculateIncludedDates = (newEndDate: Date) => {
    if (!isUpdateMode || !recurrenceSelection || !recurrenceSelection.isRepeat) return;
  
    const startDate = dayjs(getValues("startDate"));
    const endDate = dayjs(newEndDate);
    const { dropdownSelection, daySelection, radioSelection, occurencesCount, after, dateRange } = recurrenceSelection;
  
    const eventDuration = getDifferenceInDays(dayjs(dateRange[0]).toDate(), dayjs(dateRange[1]).toDate());
  
    const newIncludedDates: IncludedDateRangeObject[] = [];
  
    const getNextIntervalStart = (currentStart: dayjs.Dayjs): dayjs.Dayjs => {
      let nextStart: dayjs.Dayjs;
      switch (dropdownSelection) {
        case "DAILY":
          nextStart = currentStart.add(1, 'day');
          break;
        case "WEEKLY":
          if (daySelection.length > 0) {
            nextStart = currentStart.add(1, 'week');
            while (nextStart.day() !== daySelection[0].id) {
              nextStart = nextStart.add(1, 'day');
            }
          } else {
            nextStart = currentStart.add(1, 'week');
          }
          break;
        case "MONTHLY":
          nextStart = currentStart.add(1, 'month');
          if (nextStart.date() !== currentStart.date()) {
            nextStart = nextStart.endOf('month');
          }
          break;
        default:
          nextStart = currentStart.add(1, 'day');
      }
      return nextStart;
    };
  
    let currentStart = startDate;
  
    while (currentStart.isBefore(endDate) || currentStart.isSame(endDate, 'day')) {
      const currentEnd = currentStart.add(eventDuration, 'day');
      
      if (currentEnd.isAfter(endDate)) {
        break; // Stop if the full interval doesn't fit
      }
  
      const existingInterval = includedDates.find(interval => 
        interval.start === currentStart.format('YYYY-MM-DD') &&
        interval.end === currentEnd.format('YYYY-MM-DD')
      );
  
      if (existingInterval) {
        newIncludedDates.push(existingInterval);
      } else {
        newIncludedDates.push({
          start: currentStart.format('YYYY-MM-DD'),
          end: currentEnd.format('YYYY-MM-DD'),
          capacity: Number(getValues("capacity")),
          confirm: false,
        });
      }
  
      if (radioSelection === "Occurence" && newIncludedDates.length >= Number(occurencesCount)) {
        break;
      }
  
      if (radioSelection === "After" && currentStart.isAfter(dayjs(after))) {
        break;
      }
  
      currentStart = getNextIntervalStart(currentStart);
    }
  
    setIncludedDates(newIncludedDates);
    localStorage.removeItem("CalendarData");
  };
  // useEffect(() => {
  //   setIsUpdated(false);
  // }, []);

  const settingError = (fieldName?: string[]) => {
    const newErrors = pictureErrors.map((item) => {
      if (fieldName?.includes(item.field)) {
        return { field: item.field, error: true };
      } else {
        return { field: item.field, error: false };
      }
    });

    setPictureErrors(newErrors);
  };

  const _onSubmit = async (data: Tours) => {
    if (draftClicked) return;
    const picturesArr = [];
    if (!tourPictures.length) picturesArr.push("tourPictures");
    if (!accommodationPicture.length)
      picturesArr.push("accommodationPicture");
    if (!tourOwnerPicture.length) picturesArr.push("tourOwnerPicture");
    if (!transportationPicture.length)
      picturesArr.push("transportationPicture");

    if (
      !tourPictures.length ||
      !accommodationPicture.length ||
      !tourOwnerPicture.length ||
      !transportationPicture.length
    ) {
      // const newIncludedDates = [...includedDates]?.map((item) => {
      //   item.capacity = data.capacity;
      //   item.confirm = false;
      //   item.end = dayjs(item.end).format('YYYY-MM-DD')
      //   item.start = dayjs(item.start).format('YYYY-MM-DD')
      //   return item;
      // });
      // onSubmit({
      //   ...data,
      //   tourPictures,
      //   accommodationPicture: accommodationPicture,
      //   tourOwnerPicture,
      //   transportationPicture: transportationPicture,
      //   createdBy: userDetails?.id,
      //   dayToDay: data.dayToDay?.map((d: any) => { return { value: d.value, city: d.city } }),
      //   localSupervisorPhone: String(data.localSupervisorPhone),
      //   includedEventsDates: newIncludedDates,
      //   startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
      //   endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
      // });
      await settingError(picturesArr);
    } else {
      const newIncludedDates = [...includedDates]?.map((item) => {
        item.capacity = data.capacity;
        item.confirm = false;
        item.end = dayjs(item.end).format('YYYY-MM-DD')
        item.start = dayjs(item.start).format('YYYY-MM-DD')
        return item;
      });
      onSubmit({
        ...data,
        tourPictures,
        accommodationPicture: accommodationPicture,
        tourOwnerPicture,
        transportationPicture: transportationPicture,
        createdBy: userDetails?.id,
        dayToDay: data.dayToDay?.map((d: any) => { return { value: d.value, city: d.city } }),
        localSupervisorPhone: String(data.localSupervisorPhone),
        includedEventsDates: newIncludedDates,
        startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
        recurrenceSelection,
        lang: localStorage.getItem('language') ?? 'en'
      }, isDraft ? isDraft : false);
    }
  };

  const onSaveDraft = () => {
    const data = getValues();
    const newIncludedDates = [...includedDates]?.map((item) => {
      item.capacity = data.capacity;
      return item;
    });
    onSave!(
      {
        ...data,
        extraAccommodation: data.extraAccommodation
          ? data.extraAccommodation
          : [],
        tourPictures,
        accommodationPicture: accommodationPicture,
        tourOwnerPicture,
        transportationPicture: transportationPicture,
        createdBy: userDetails?.id,
        dayToDay: data.dayToDay?.map((d: any) => { return { value: d.value, city: d.city } }),
        includedEventsDates: newIncludedDates,
        localSupervisorPhone: String(data.localSupervisorPhone),
        // feedback: initialData?.feedback?.map((v) => ({ value: v })) || [],
      },
      true
    );
  };

  const saveTourDraft = async () => {
    setDraftClicked(true);
    const data = getValues();
    const newIncludedDates = [...includedDates]?.map((item) => {
      item.capacity = data.capacity;
      return item;
    });
    const requestData = {
      ...data,
        tourPictures,
        accommodationPicture: accommodationPicture,
        tourOwnerPicture,
        transportationPicture: transportationPicture,
        createdBy: userDetails?.id,
        dayToDay: data.dayToDay?.map((d: any) => { return { value: d.value, city: d.city } }),
        localSupervisorPhone: String(data.localSupervisorPhone),
        includedEventsDates: newIncludedDates,
        startDate: dayjs(data.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
        recurrenceSelection,
        lang: localStorage.getItem('language') ?? 'en'
    }
    try {
      await dispatch(saveEventAsDraft(requestData, 'tour'));
      toast.success("Saved Draft");
      setDraftClicked(false);
      // history.push('/profile/tours')
    } catch (error: any) {
      toast.error(error.response.data.err.map((e: any) => e.message).join('\n'));
    }
  }

  const { duration } = watch(["duration"]);

  const selectedAccomdations = useMemo(() => {
    return [
      ...(extraAccommodation?.map((acc) => acc.category) || []),
      defaultAccomadation,
    ];
  }, [extraAccommodation, defaultAccomadation]);
  const selectedChoiceOfFood = useMemo(() => {
    return [
      ...(extraChoiceOfFood?.map((acc) => acc.category) || []),
      choiceOfFood,
    ];
  }, [choiceOfFood, extraChoiceOfFood]);
  const _ToggleCalendarModal = () => {
    if (dayjs(getValues("startDate")).isValid() && dayjs(getValues("endDate")).isValid()) {
      setShowCalendarModal(!showCalendarModal);
      setShowCalendarModalError(false);
    } else {
      setShowCalendarModalError(true)
    }
  };

  const checkError = (fieldName: string) => {
    const error = pictureErrors.find((item) => item.field === fieldName);
    return error?.error;
  };

  const removeError = (fieldName: string) => {
    const updatedErrors = pictureErrors.map((item) => {
      if (item.field === fieldName) return { field: fieldName, error: false };
      return item;
    });
    setPictureErrors(updatedErrors);
  };
  useEffect(() => {
    if (initialData?.feedback?.length)
      return
    for (let i = 0; i < 1; i++) {
      appendFeedback('', false)
    }
  }, [])
  return (
    <>
      <Modal
        show={showStandards !== undefined}
        onHide={() => setShowStandards(undefined)}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">{tourLan?.tour_standard}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Language?.general?.tourStandards?.map((item: any) =>
            <li key={item.text}>
              <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"} style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
              {item.text}
            </li>
          )}
        </Modal.Body>
      </Modal>
      <form
        className="respadding com-font sa-custom-form"
        onSubmit={handleSubmit(_onSubmit)}
      >
        <div className="form-group">
          <div className="row">
            <div className="col-lg-12">
              <label
                htmlFor="idofthetour"
                className="mb-0 sa-label small text-capitalize"
              >
                {tourLan?.custom_id}
              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                name="customId"
                id="idofthetour"
                placeholder={tourLan?.custom_id_place}
                ref={register}
              ></input>
              {errors.customId && (
                <div className="invalid-feedback d-block">
                  {tourLan?.custom_id_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="tourname"
                className="mb-0 sa-label small text-capitalize"
              >
                {tourLan?.tour_name}
              </label>
              <input
                name="tourName"
                type="text"
                className="form-control resforminp mt-0"
                id="tourname"
                placeholder={tourLan?.tour_name_place}
                ref={register}
              ></input>
              {errors.tourName && (
                <div className="invalid-feedback d-block">
                  {tourLan?.tour_name_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="tourdescription"
                className="mb-0 sa-label small text-capitalize"
              >
                {tourLan?.tour_desc}
              </label>
              <textarea
                name="tourDescription"
                id=""
                cols={30}
                rows={10}
                className="form-control"
                ref={register}
                placeholder={tourLan?.tour_desc_place}
              ></textarea>
              {errors.tourDescription && (
                <div className="invalid-feedback d-block">
                  {tourLan?.tour_desc_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="spokenLanguages"
                className="mb-0 sa-label small text-capitalize"
              >
                {tourLan?.spoken_languages}
              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                id="spokenLanguages"
                name="spokenLanguages"
                placeholder={tourLan?.spoken_languages_place}
                ref={register}
              ></input>
              {errors.spokenLanguages && (
                <div className="invalid-feedback d-block">
                  {tourLan?.spoken_languages_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label htmlFor="natureOfEvent" className="mb-0 small text-capitalize">
                {tourLan?.type_of_tour}
              </label>
              <select
                title={tourLan?.type_of_tour_place}
                className="form-control resforminp mt-0"
                id="natureOfEvent"
                name="natureOfEvent"
                ref={register}
              >
                <option value=''>{Language?.general?.select}</option>
                {tourLan?.typeOfEventOptions?.length && tourLan?.typeOfEventOptions.map((item: any) => <option key={item?.option} value={translateToEng(item?.option)}>{item?.option}</option>)}
              </select>
              {errors.natureOfEvent && (
                <div className="invalid-feedback d-block">
                  {tourLan?.type_of_tour_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="natureOfEventdescription"
                className="mb-0 sa-label small text-capitalize"
              >
                {tourLan?.type_of_tour_desc}
              </label>
              <textarea
                name="natureOfEventdescription"
                id="natureOfEventdescription"
                cols={30}
                rows={10}
                className="form-control"
                ref={register}
                placeholder={tourLan?.type_of_tour_desc_place}
              ></textarea>
              {errors.natureOfEventdescription && (
                <div className="invalid-feedback d-block">
                  {tourLan?.type_of_tour_desc_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <FileUpload
                label={tourLan?.tour_pictures}
                formatLabel={tourLan?.tour_pictures_place}
                multiple={true}
                value={tourPictures}
                onChange={(val) => {
                  setTourPictures(val as CloudinaryImg[])
                  removeError('tourPictures')
                }}
                destination={'tour'}
                field={'tourPictures'}
                isEvent={location?.pathname.split("/").pop() || false}
                removeFromDb={location.pathname !== "/profile/tours/add" ? true : false}
              />
              {checkError("tourPictures") && (
                <div className="invalid-feedback d-block">
                  {tourLan?.tour_pictures_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label htmlFor="levelOfDifficulty" className="mb-0 small text-capitalize">
                {tourLan?.tour_difficulty}
              </label>
              <select
                className="form-control resforminp mt-0"
                id="levelOfDifficulty"
                name="levelOfDifficulty"
                ref={register}
                title={tourLan?.tour_difficulty_place}
              >
                <option value=''>{Language?.general?.select}</option>
                {tourLan?.levelOfDifficultyOptions?.length &&
                  tourLan?.levelOfDifficultyOptions.map((item: any, i: number) =>
                    <option key={item.option} value={translateToEng(item.option)}>{item.option}</option>
                  )}
              </select>
              {errors.levelOfDifficulty && (
                <div className="invalid-feedback d-block">
                  {tourLan?.tour_difficulty_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="ownername"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {tourLan?.owner_name}
              </label>
              <input
                name="tourOwnerName"
                type="text"
                className="form-control resforminp mt-0"
                id="ownername"
                placeholder={tourLan?.owner_name_place}
                ref={register}
              ></input>
              {errors.tourOwnerName && (
                <div className="invalid-feedback d-block">
                  {tourLan?.owner_name_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="descriptionabout"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {tourLan?.owner_desc}
              </label>
              <textarea
                name="ownerDescription"
                id="descriptionabout"
                cols={30}
                rows={5}
                className="form-control mb-3"
                ref={register}
                placeholder={tourLan?.owner_desc_place}
              ></textarea>
              {errors.ownerDescription && (
                <div className="invalid-feedback d-block">
                  {tourLan?.owner_desc_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <FileUpload
                label={tourLan?.owner_pictures}
                multiple={true}
                formatLabel={tourLan?.owner_pictures_place}
                value={tourOwnerPicture}
                onChange={(val) => {
                  setTourOwnerPicture(val as CloudinaryImg[])
                  removeError('tourOwnerPicture')
                }}
                destination={'tour'}
                field={'tourOwnerPicture'}
                isEvent={location?.pathname.split("/").pop() || false}
                removeFromDb={location.pathname !== "/profile/tours/add" ? true : false}
              />
              {checkError("tourOwnerPicture") && (
                <div className="invalid-feedback d-block">
                  {tourLan?.owner_pictures_err}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label
                htmlFor="supervisorname"
                className="mb-0 sa-label small text-capitalize">
                {tourLan?.local_supervisor_name}
              </label>
              <input
                name="localSupervisorName"
                type="text"
                className="form-control resforminp mt-0"
                id="supervisorname"
                placeholder={tourLan?.local_supervisor_name_place}
                ref={register}
              ></input>
              {errors.localSupervisorName && (
                <div className="invalid-feedback d-block">
                  {tourLan?.local_supervisor_name_err}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label
                htmlFor="supervisorphone"
                className="mb-0 sa-label small text-capitalize"
              >
                {tourLan?.local_supervisor_phone}
              </label>
              <input
                name="localSupervisorPhone"
                type="text"
                className="form-control resforminp mt-0"
                id="supervisorphone"
                placeholder={tourLan?.local_supervisor_phone_place}
                ref={register}
              ></input>
              {errors.localSupervisorPhone && (
                <div className="invalid-feedback d-block">{tourLan?.local_supervisor_phone_err}</div>
              )}
            </div>
            <div className="col-lg-6">
              <label htmlFor="location" className="mb-0 small text-capitalize">
                {tourLan?.location}
              </label>
              <select
                name="location"
                className="form-control resforminp mt-0"
                id="location"
                ref={register}
              >
                {Countries.map((c) => (
                  <option key={c.name} value={c.name}>{c.name}</option>
                ))}
              </select>
              {errors.location && (
                <div className="invalid-feedback d-block">
                  {tourLan?.location_err}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label htmlFor="duration" className="mb-0 small text-capitalize">
                {tourLan?.duration}
              </label>
              <select
                name="duration"
                className="form-control resforminp mt-0"
                id="duration"
                // disabled
                ref={register}
              >
                <option disabled value="">
                  {tourLan?.duration_place || "duration"}
                </option>
                {range(1, 1000)?.map((i) => (
                  <option key={i} value={i}>{i}</option>
                ))}
              </select>
              {errors.duration && (
                <div className="invalid-feedback d-block">
                  {tourLan?.duration_err}
                </div>
              )}
            </div>

            {/* <div className={`col-lg-6 ${initialData?.available && initialData?.status === 'approved' && userDetails.swt_role === 'provider' && location.pathname !== "/profile/tours/add" && 'd-none'}`}> */}
            <div className={`col-lg-6`}>
              <label
                htmlFor="availablefrom"
                className="mb-0 sa-label small text-capitalize"
              >
                {tourLan?.available_from}
              </label>

              <input
                name="startDate"
                type="date"
                className="form-control resforminp mt-0"
                id="availablefrom"
                placeholder={tourLan?.available_from_place}
                // min={isDraft ? dayjs(initialData?.endDate).format("YYYY-MM-DD") : initialData?.endDate ? dayjs(initialData?.endDate).format("YYYY-MM-DD") : minDate}
                min={isDraft ? dayjs().add(1, "day").format("YYYY-MM-DD") : initialData?.startDate ? dayjs(initialData.startDate).format("YYYY-MM-DD") : dayjs().add(1, "day").format("YYYY-MM-DD")}

                ref={register}
                onChange={(e) => {
                  if (dayjs(e.target.value).isValid()) {

                    setValue(
                      "endDate",
                      dayjs(e.target.value).add(1, "day").format("YYYY-MM-DD")
                    );

                    setMinDate(
                      dayjs(e.target.value).add(1, "day").format("YYYY-MM-DD")
                    );
                    localStorage.removeItem("CalendarData");
                  }
                }}
                readOnly={
                  (initialData?.available && initialData?.status === 'approved' && userDetails.swt_role === 'provider' && location.pathname !== "/profile/tours/add") || 
                  initialData?.status === 'expired' || 
                  (initialData?.status === 'pending' && hasReservations ) ? true : false
                }
                // readOnly
              ></input>
              {errors.startDate && (
                <div className="invalid-feedback d-block">
                  Start Date is required
                </div>
              )}
              {!dayjs(startDate).isValid() && (
                <div className="invalid-feedback d-block">
                  {tourLan?.available_from_err}
                </div>
              )}
            </div>
            {/* <div className={`col-lg-6 ${initialData?.available && initialData?.status === 'approved' && userDetails.swt_role === 'provider' && location.pathname !== "/profile/tours/add" && 'd-none'}`}> */}
            <div className={`col-lg-6`}>
              <label
                htmlFor="availableuntil"
                className="mb-0 sa-label small text-capitalize"
              >
                {tourLan?.available_until}
              </label>
              <input
                name="endDate"
                type="date"
                className="form-control resforminp mt-0"
                id="availableuntil"
                placeholder="Available until"
                ref={register}
                // min={minDate}
                min={isDraft ? dayjs(minDate).add(1, "day").format("YYYY-MM-DD") : initialData?.endDate ? dayjs(initialData?.endDate).format("YYYY-MM-DD") : minDate}
                // min={initialData?.endDate ? dayjs(initialData?.endDate).format("YYYY-MM-DD") : dayjs(minDate).add(1, "day").format("YYYY-MM-DD")}
                onChange={(e) => {
                  if (dayjs(e.target.value).isValid()) {
                    // setValue(
                    //   "duration",
                    //   (
                    //     getDifferenceInDays(
                    //       dayjs(getValues("startDate")).toDate(),
                    //       dayjs(e.target.value).toDate()
                    //     ) + 1
                    //   ).toString()
                    // );
                    localStorage.removeItem("CalendarData")
                  }
                }}
                readOnly={initialData?.status === 'expired' && userDetails.swt_role === 'provider' && location.pathname !== "/profile/tours/add" ? true : false}
                // max={initialData?.status !== 'approved' && userDetails.swt_role === 'provider' && location.pathname !== "/profile/tours/add" ? dayjs().add(1, 'year').format("YYYY-MM-DD") : undefined}
              ></input>
              {errors.endDate && (
                <div className="invalid-feedback d-block">
                  End Date is required
                </div>
              )}
              {!dayjs(endDate).isValid() && (
                <div className="invalid-feedback d-block">
                  {tourLan?.available_until_err}
                </div>
              )}
            </div>
            {/* <div className={`col-lg-6 ${initialData?.status !== 'approved' && userDetails.swt_role === 'provider' && 'd-none'}`}>
              <label
                htmlFor="availablefrom"
                className="mb-0 sa-label small text-capitalize"
              >
                {tourLan?.available_from}
              </label>

              <input
                type="date"
                className="form-control resforminp mt-0"
                id="availablefrom"
                placeholder={tourLan?.available_from_place}
                value={startDate}
                disabled
              ></input>
            </div>
            <div className={`col-lg-6 ${initialData?.status !== 'approved' && userDetails.swt_role === 'provider' && 'd-none'}`}>
              <label
                htmlFor="availableuntil"
                className="mb-0 sa-label small text-capitalize"
              >
                {tourLan?.available_until}
              </label>

              <input
                type="date"
                className="form-control resforminp mt-0"
                id="availableuntil"
                placeholder={tourLan?.available_until_place}
                value={endDate}
                disabled
              ></input>
            </div> */}
            <div className={`col-lg-12 ${initialData?.available && initialData?.status === 'approved' && userDetails.swt_role === 'provider' && location.pathname !== "/profile/tours/add" && 'd-none'}`}>
              <p
                // className="btn btn-sm btn-link btnscart"
                className="btn btn-default btnviewproj text-capitalize  mt-3"

                onClick={_ToggleCalendarModal} >
                {tourLan?.open_date_modal}
              </p>
              {showCalendarModalError && !dayjs(endDate).isValid() || showCalendarModalError && !dayjs(startDate).isValid() && (
                <div className="invalid-feedback d-block">
                  Selected Dates are not valid
                </div>
              )}
            </div>
            <Modal
              size="lg"
              closeButton
              show={showCalendarModal}
              onHide={_ToggleCalendarModal}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <Calendar
                  setCalendarDateRange={setCalendarDateRange}
                  setIncludedDates={setIncludedDates}
                  startDate={dayjs(getValues("startDate")).toDate()}
                  endDate={dayjs(getValues("endDate")).toDate()}
                  setValue={setValue}
                  trigger={trigger}
                  initialData={initialData}
                  toggleModal={_ToggleCalendarModal}
                  setRecurrenceSelection={setRecurrenceSelection}
                />
              </Modal.Body>
            </Modal>
            <div className="col-lg-6">
              <label htmlFor="capacity" className="mb-0 small text-capitalize">
                {tourLan?.capacity}
              </label>
              <select
                name="capacity"
                className="form-control resforminp mt-0"
                id="capacity"
                ref={register}
              >
                <option disabled value="">
                  {tourLan?.capacity_place}
                </option>
                {range(1, 101).map((i) => (
                  <option key={i} value={i}>{i}</option>
                ))}
              </select>
              {errors.capacity && (
                <div className="invalid-feedback d-block">
                  {tourLan?.capacity_err}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label
                htmlFor="minCapacity"
                className="mb-0 small text-capitalize"
              >
                {tourLan?.min_participants}
              </label>
              <select
                name="minCapacity"
                className="form-control resforminp mt-0"
                id="minCapacity"
                ref={register}
              >
                <option disabled value="">
                  {tourLan?.min_participants_place}
                </option>
                {range(1, 101).map((i) => (
                  <option key={i} value={i}>{i}</option>
                ))}
              </select>
              {errors.minCapacity && (
                <div className="invalid-feedback d-block">
                  {tourLan?.min_participants_err}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label
                htmlFor="noticeperiod"
                className="mb-0 small text-capitalize"
              >
                {tourLan?.notice_period}
              </label>
              <select
                name="noticePeriod"
                className="form-control resforminp mt-0"
                id="noticeperiod"
                // data-toggle="tooltip"
                // data-placement="top"
                // title={tourLan?.notice_period_info}
                ref={register}
              >
                <option disabled value="">
                  {tourLan?.notice_period_place}
                </option>
                {range(31, 101).map((i) => (
                  <option key={i} value={i}>{i}</option>
                ))}
              </select>
              {errors.noticePeriod && (
                <div className="invalid-feedback d-block">
                  {tourLan?.notice_period_err}
                </div>
              )}
            </div>
            <Tooltip
              anchorSelect="#noticeperiod"
              place="top"
              content={tourLan?.notice_period_info}
            />
            <div className="col-lg-6">
              <label
                htmlFor="totalcost"
                className="mb-0 sa-label small text-capitalize"
              >
                {tourLan?.tour_cost}
              </label>
              <input
                name="price"
                type="text"
                className="form-control resforminp mt-0"
                id="totalcost"
                placeholder={tourLan?.tour_cost_place}
                ref={register}
              ></input>
              {errors.price && (
                <div className="invalid-feedback d-block">
                  {tourLan?.tour_cost_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <label
                htmlFor="additionalPrice"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {tourLan?.additional_cost}
              </label>
              <textarea
                name="additionalPrice"
                id="additionalPrice"
                cols={30}
                rows={3}
                className="form-control"
                placeholder={tourLan?.additional_cost_place}
                ref={register}
              ></textarea>
            </div>
            <div className="col-lg-11">
              <label
                htmlFor="sdg"
                className="mb-0 small text-capitalize"
              >
                {tourLan?.sdg}
              </label>
              <select
                name="sdg"
                className="form-control resforminp mt-0"
                id="sdg"
                ref={register}
                title={tourLan?.sdg_place}
              >

                <option value="">{Language?.general?.select}</option>
                {tourLan?.sdgOptions?.length && tourLan?.sdgOptions.map(
                  (opt:any, i:number) => (
                    <option
                      key={opt?.option}
                      value={translateToEng(opt?.option)}
                    >
                      {tourLan?.sdgOptions[i]?.option}
                    </option>
                  )
                )}
              </select>
              {errors.sdg && (
                <div className="invalid-feedback d-block">
                  {tourLan?.sdg_err}
                </div>
              )}
            </div>
            <div className="col-lg-9">
              <label
                htmlFor="accomodation"
                className="mb-0 small text-capitalize"
              >
                {tourLan?.standard_accomodation}
              </label>
              <select
                name="category"
                className="form-control resforminp mt-0"
                id="accomodation"
                ref={register}
              >
                <option selected disabled>
                  {tourLan?.standard_accomodation_place}

                </option>
                {/* <option value="">{Language?.general?.select}</option> */}
                {tourLan?.accomodationOptions?.length && tourLan?.accomodationOptions.map(
                  (opt: any) => (
                    <option
                      key={opt.option}
                      value={translateToEng(opt.option)}
                      disabled={selectedAccomdations.includes(opt.option)}
                      // value={opt.option}
                    >
                      {opt.option}
                    </option>
                  )
                )}
              </select>
              {errors.standardAccomodation && (
                <div className="invalid-feedback d-block">
                  {tourLan?.standard_accomodation_err}
                </div>
              )}
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              <a
                className="mb-0"
                onClick={() => setShowStandards(defaultAccomadation)}
              >
                {tourLan?.tour_standard}
              </a>
            </div>
            {defaultAccomadation && (
              <div className="col-lg-12">
                <label
                  htmlFor="descriptionaccomodation"
                  className="mb-0 mt-3 sa-label small text-capitalize"
                >
                  {tourLan?.included_standards}
                </label>
                <ul style={{ paddingLeft: "0px" }} className="list-none ">
                  {tourLan?.standards[defaultAccomadation]?.map((item: any) =>
                    <li key={item.value}>
                      <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"}
                        style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                      {item.value}
                    </li>
                  )}
                </ul>
              </div>
            )}
            <div className="col-lg-12">
              <label
                htmlFor="descriptionaccomodation"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {tourLan?.accomodation_desc}
              </label>
              <textarea
                name="accommodationDescription"
                id="descriptionaccomodation"
                cols={30}
                rows={5}
                className="form-control"
                ref={register}
                placeholder={tourLan?.accomodation_desc_place}
              ></textarea>
              {errors.accommodationDescription && (
                <div className="invalid-feedback d-block">
                  {tourLan?.accomodation_desc_err}
                </div>
              )}
            </div>
            {extraAccommodationFields.map((extraAccommodationField, index) => {
              return (
                <>
                  <div className="col-lg-8">
                    <label
                      htmlFor="accomodation"
                      className="mb-0 small text-capitalize"
                    >
                      {tourLan?.extra_accomodation_category}
                    </label>
                    <select
                      className="form-control resforminp mt-0"
                      id="accomodation"
                      name={`extraAccommodation[${index}].category`}
                      defaultValue={extraAccommodationField.category}
                      data-value={extraAccommodation[index]?.category}
                      ref={register}
                    >
                      <option value="">{Language?.general.select}</option>
                      {tourLan?.accomodationOptions.length && tourLan?.accomodationOptions?.map((opt: any, i:number) => (
                        <option
                          key={i}
                          disabled={selectedAccomdations.includes(translateToEng(opt.option))}
                          value={translateToEng(opt.option)}
                        >
                          {opt.option}
                        </option>
                      ))}
                    </select>
                    {errors.extraAccommodation?.[index]?.category && (
                      <div className="invalid-feedback d-block">
                        {tourLan?.extra_accomodation_err}

                      </div>
                    )}
                  </div>
                  <div className="col-lg-3">
                    <label
                      htmlFor={`extraAccommodation[${index}].extraPrice`}
                      className="mb-0 small text-capitalize"
                    >
                      {tourLan?.extra_accomodation_per_night}
                    </label>
                    <input
                      type="text"
                      className="form-control resforminp mt-0"
                      id={`extraAccommodation[${index}].extraPrice`}
                      name={`extraAccommodation[${index}].extraPrice`}
                      defaultValue={extraAccommodationField.extraPrice}
                      placeholder={tourLan?.extra_accomodation_per_night_place}
                      ref={register}
                    ></input>
                    {errors.extraAccommodation?.[index]?.extraPrice && (
                      <div className="invalid-feedback d-block">
                        {tourLan?.extra_accomodation_per_night_err}

                      </div>
                    )}
                  </div>
                  <div className="col-lg-1" style={{ alignSelf: "center" }}>
                    <button
                      className="btn btn-sm btn-link btnscart"
                      onClick={() => removeExtraAccomodation(index)}
                    >
                      <img
                        src="/images/delete_icon.svg"
                        className="img-fluid carticon"
                        alt=""
                      />
                    </button>
                  </div>
                  {extraAccommodation[index]?.category && (
                    <div className="col-lg-12">
                      <label
                        htmlFor="descriptionaccomodation"
                        className="mb-0 mt-3 sa-label small text-capitalize"
                      >
                        {tourLan?.accomodation_standard}
                      </label>
                      {
                        <ul style={{ paddingLeft: "0px" }} className="list-none ">
                          {tourLan?.standards[extraAccommodation[index].category]?.map((item: any) =>
                            <li>
                              <img alt="" src={item?.included ? '/images/checkmark-red.svg' : "/images/crossx.svg"}
                                style={{ height: '13px', width: '15px' }} className="img-fluid" />{" "}
                              {item.value}
                            </li>
                          )}
                        </ul>
                      }
                    </div>
                  )}
                  <div className="col-lg-12">
                    <label
                      htmlFor="descriptionaccomodation"
                      className="mb-0 mt-3 sa-label small text-capitalize"
                    >
                      {tourLan?.description_accomodation}
                    </label>
                    <textarea
                      name={`extraAccommodation[${index}].description`}
                      id={`extraAccommodation[${index}].description`}
                      defaultValue={extraAccommodationField.description}
                      cols={30}
                      rows={5}
                      className="form-control"
                      placeholder={tourLan?.description_accomodation_place}
                      ref={register}
                    ></textarea>
                    {errors.extraAccommodation?.[index]?.description && (
                      <div className="invalid-feedback d-block">
                        {tourLan?.description_accomodation_err}
                      </div>
                    )}
                  </div>
                </>
              );
            })}
            <div className="col-lg-12">
              <button
                type="button"
                data-toggle="modal"
                data-target="#paymentInfoModal"
                className="btn btn-default btnviewproj text-capitalize mt-3"
                disabled={extraAccommodationFields?.length >= 2}
                onClick={() =>
                  appendExtraAccomodation({
                    category: "",
                    extraPrice: "",
                    description: "",
                  })
                }
              >
                {tourLan?.extra_accomodation}
              </button>
            </div>
            <div className="col-lg-12">
              <FileUpload
                label={tourLan?.accomodation_pictures}
                formatLabel={tourLan?.accomodation_pictures_place}
                multiple={true}
                value={accommodationPicture}
                onChange={(val) => {
                  setAccommodationPicture(val as CloudinaryImg[])
                  removeError('accommodationPicture')
                }}
                destination={'tour'}
                field={'accommodationPicture'}
                isEvent={location?.pathname.split("/").pop() || false}
                removeFromDb={location.pathname !== "/profile/tours/add" ? true : false}
              />
              {checkError("accommodationPicture") && (
                <div className="invalid-feedback d-block">
                  {tourLan?.accomodation_pictures_err}
                </div>
              )}
            </div>

            <div className="col-lg-12">
              <label
                htmlFor="transportation"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {tourLan?.transportation}
              </label>
              <input
                name="transportation"
                type="text"
                className="form-control resforminp mt-0"
                id="transportation"
                ref={register}
                placeholder={tourLan?.transportation_place}
              ></input>
              {errors.transportation && (
                <div className="invalid-feedback d-block">
                  {tourLan?.transportation_err}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label
                htmlFor="airportCost"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {tourLan?.airport_cost}
              </label>
              <input
                name="airportCost"
                type="text"
                className="form-control resforminp mt-0"
                id="airportCost"
                ref={register}
                placeholder={tourLan?.airport_cost_place}
              ></input>
              {errors.airportCost && (
                <div className="invalid-feedback d-block">
                  {tourLan?.airport_cost_err}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label
                htmlFor="nearAirport"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {tourLan?.near_airport}
              </label>
              <input
                type="text"
                className="form-control resforminp mt-0"
                id="nearAirport"
                placeholder={tourLan?.near_airport_place}
                title={tourLan?.near_airport_place}
                name="nearAirport"
                ref={register}

              ></input>
              {errors.nearAirport && (
                <div className="invalid-feedback d-block">
                  {tourLan?.near_airport_err}
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <FileUpload
                label={tourLan?.transportation_pictures}
                formatLabel={tourLan?.transportation_pictures_place}
                multiple={true}
                value={transportationPicture}
                onChange={(val) => {
                  setTransportationPicture(val as CloudinaryImg[])
                  removeError('transportationPicture')
                }}
                destination={'tour'}
                field={'transportationPicture'}
                isEvent={location?.pathname.split("/").pop() || false}
                removeFromDb={location.pathname !== "/profile/tours/add" ? true : false}
              />
              {checkError("transportationPicture") && (
                <div className="invalid-feedback d-block">
                  {tourLan?.transportation_pictures_err}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <label
                htmlFor="choiceOfFood"
                className="mb-0 small text-capitalize"
              >
                {tourLan?.choice_of_food}

              </label>
              <select
                title={tourLan?.choice_of_food_sub_snacks}
                name="choiceOfFood"
                className="form-control resforminp mt-0"
                id="choiceOfFood"
                ref={register}
              >
                <option selected disabled value="">{Language?.general?.select}</option>
                {tourLan?.choiceOfFoodOptions.length && tourLan?.choiceOfFoodOptions.map((item:any)=>(
                  <option
                    key={item.option}
                    // value={item.option}
                    value={translateToEng(item.option)}
                    disabled={selectedChoiceOfFood?.includes(item.option)}>
                    {item.option}
                  </option>
                ))}
              </select>
              {errors.choiceOfFood && (
                <div className="invalid-feedback d-block">
                  {tourLan?.choice_of_food_err}
                </div>
              )}
            </div>
            {choiceOfFood?.includes("3") &&
              <div className="col-lg-6">
                <label
                  htmlFor="choiceOfFood"
                  className="mb-0 small text-capitalize"
                >
                  {tourLan?.food_extra_price}
                </label>
                <input
                  name="foodPrice"
                  type="text"
                  className="form-control resforminp mt-0"
                  id="foodPrice"
                  ref={register}
                  placeholder={tourLan?.food_extra_price_place}
                ></input>
                {errors.foodPrice && (
                  <div className="invalid-feedback d-block">
                    {tourLan?.food_extra_price_err}
                  </div>
                )}
              </div>
            }
            <div className="col-lg-12">
              <label
                htmlFor="offeredmeals"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {tourLan?.offered_meals} {choiceOfFood?.includes("Snacks") ? tourLan?.snacks : tourLan?.meals}
              </label>
              <textarea
                name="foodDescription"
                id="offeredmeals"
                cols={30}
                rows={3}
                className="form-control"
                ref={register}
                placeholder={choiceOfFood?.includes("Snacks")
                  ? tourLan?.offered_meals_place1 :
                  tourLan?.offered_meals_place2}
              ></textarea>
              {errors.foodDescription && (
                <div className="invalid-feedback d-block">
                  {tourLan?.offered_meals_err}
                </div>
              )}
            </div>
            {extraChoiceOfFoodFields?.map((extraChoiceOfFoodField, index) => {
              return (
                <React.Fragment key={extraChoiceOfFoodField.category}>
                  <div className="col-lg-6">
                    <label
                      htmlFor="choiceOfFood"
                      className="mb-0 small text-capitalize"
                    >
                      {tourLan?.choice_of_food}
                    </label>
                    <select
                      defaultValue={extraChoiceOfFoodField.category}
                      name={`extraChoiceOfFood[${index}].category`}
                      className="form-control resforminp mt-0"
                      id={`extraChoiceOfFood[${index}].category`}
                      ref={register}
                    >
                      <option selected disabled value="">{Language?.general?.select}</option>
                      {tourLan?.choiceOfFoodOptions.length && tourLan?.choiceOfFoodOptions?.map((item: any, key: number) => <option key={key} disabled={selectedChoiceOfFood.includes(translateToEng(item.option))} value={translateToEng(item.option)}>{item.option}</option>)}
                    </select>
                    {errors?.extraChoiceOfFood?.[index]?.category && (
                      <div className="invalid-feedback d-block">
                        {tourLan?.choice_of_food_err}
                      </div>
                    )}
                  </div>
                  {extraChoiceOfFood[index].category.includes("3") &&
                    <div className="col-lg-6">
                      <label
                        htmlFor="foodPrice"
                        className="mb-0 small text-capitalize"
                      >
                        {tourLan?.food_extra_price}
                      </label>
                      <input
                        name="foodPrice"
                        type="text"
                        className="form-control resforminp mt-0"
                        id="foodPrice"
                        ref={register}
                        placeholder={tourLan?.food_extra_price_place}
                      ></input>
                      {errors.foodPrice && (
                        <div className="invalid-feedback d-block">
                          {tourLan?.food_extra_price_err}
                        </div>
                      )}
                    </div>
                  }
                  <div className="col-lg-11">
                    <label
                      htmlFor="offeredmeals"
                      className="mb-0 mt-3 sa-label small text-capitalize"
                    >
                      {tourLan?.offered_meals} {extraChoiceOfFood[index]?.category === "snacks & fresh water" ? 'snacks' : "meals"}
                    </label>
                    <textarea
                      defaultValue={extraChoiceOfFoodField?.description}
                      name={`extraChoiceOfFood[${index}].description`}
                      id={`extraChoiceOfFood[${index}].description`}
                      cols={30}
                      rows={3}
                      className="form-control"
                      ref={register}
                      placeholder={extraChoiceOfFood[index]?.category === "snacks & fresh water"
                        ? tourLan?.offered_meals_place1 :
                        tourLan?.offered_meals_place2}
                    ></textarea>
                    {errors?.extraChoiceOfFood?.[index]?.description && (
                      <div className="invalid-feedback d-block">
                        {tourLan?.offered_meals_err}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-1" style={{ alignSelf: "center" }}>
                    <button
                      className="btn btn-sm btn-link btnscart"
                      onClick={() => removeextraChoiceOfFood(index)}
                    >
                      <img
                        src="/images/delete_icon.svg"
                        className="img-fluid carticon"
                        alt=""
                      />
                    </button>
                  </div>
                </React.Fragment>
              );
            })}


            <div className="col-lg-12">
              <button
                type="button"
                data-toggle="modal"
                data-target="#paymentInfoModal"
                className="btn btn-default btnviewproj text-capitalize mt-3"
                disabled={extraChoiceOfFoodFields?.length >= 1}
                onClick={() =>
                  appendExtraChoiceOfFood({
                    category: "",
                    description: "",
                  })
                }
              >
                {tourLan?.extra_choice_of_food}
              </button>
            </div>

            {duration &&
              [...new Array(parseInt(duration as any))]?.map(
                (dayToDay, index) => {
                  return (<React.Fragment key={index}>
                    <div className="col-lg-8">
                      <label
                        htmlFor="days"
                        className="mb-0 mt-3 sa-label small text-capitalize"
                      >
                        {tourLan?.describe_day} {index + 1}
                      </label>
                      <input
                        name={`dayToDay[${index}].value`}
                        type="text"
                        className="form-control resforminp mt-0"
                        id={`days${index}`}
                        placeholder={tourLan?.describe_day_place}
                        ref={register}
                        defaultValue={(dayToDayFields[index] as any)?.value}
                      />
                      {errors.dayToDay?.[index]?.value && (
                        <div className="invalid-feedback d-block">
                          {tourLan?.describe_day_err}
                        </div>
                      )}
                    </div>
                    <div className="col-lg-4">
                      <label
                        htmlFor="days"
                        className="mb-0 mt-3 sa-label small text-capitalize"
                      >
                        {tourLan?.describe_city}
                      </label>
                      <AutoComplete
                        name={`dayToDay[${index}].city`}
                        type="text"
                        className="form-control resforminp mt-0"
                        id={`city${index}`}
                        placeholder={tourLan?.describe_city_place}
                        ref={register}
                        defaultValue={(dayToDayFields[index] as any)?.city}
                      />
                      {errors.dayToDay?.[index]?.city && (
                        <div className="invalid-feedback d-block">
                          {tourLan?.describe_city_err}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                  );
                }
              )}

            <div className="col-lg-12">
              <label
                htmlFor="offeredmeals"
                className="mb-0 mt-3 sa-label small text-capitalize"
              >
                {tourLan?.additional_info}
              </label>
              <textarea
                name="additionalInfo"
                id="offeredmeals"
                cols={30}
                rows={3}
                className="form-control"
                ref={register}
                placeholder={tourLan?.additional_info_place}
              ></textarea>
              {errors.additionalInfo && (
                <div className="invalid-feedback d-block">
                  {tourLan?.additional_info_err}
                </div>
              )}
            </div>
            <div className="col-lg-12 pt-3 mt-3">
              <p className="com-font">
                {tourLan?.feedback}
              </p>
            </div>
            {feedbackFields.map((field, index) => (
              <React.Fragment key={index}>
                <div className="col-lg-11 pt-3">
                  <label  >
                    {tourLan?.add_feedback}  {index + 1}
                  </label>
                  <textarea
                    name={`feedback[${index}].value`}
                    className="form-control"
                    id={`feedback${index}`}
                    placeholder={tourLan?.add_feedback_place}
                    ref={register}
                    defaultValue={(feedbackFields[index] as any)?.value}
                  ></textarea>
                  {errors.feedback?.[index] && (
                    <div className="invalid-feedback d-block">required</div>
                  )}
                </div>
                <div className="col-lg-1" style={{ alignSelf: "center" }}>
                  <button
                    className="btn btn-sm btn-link btnscart"
                    onClick={() => removeFeedback(index)}
                  >
                    <img
                      src="/images/delete_icon.svg"
                      className="img-fluid carticon"
                      alt=""
                    />
                  </button>
                </div>
              </React.Fragment>
            ))}
            <div className="col-lg-12">

              <button
                type="button"
                className="btn btn-default btnviewproj text-capitalize mt-2"
                onClick={() => appendFeedback("")}
              >
                {tourLan?.add_feedback_btn}
              </button>
            </div>

            <div className="col-lg-12">
              {isProviderForm ? (
                <>

                  <button
                    className="btn btn-default btnSaveandStart text-capitalize mt-3"
                    onClick={onSaveDraft}
                  >
                    {Language?.general?.save_as_draft}
                  </button>

                  <button
                    type="button"
                    className="btn btn-default btnSaveandStart text-capitalize mt-3 ml-2"
                    onClick={onStartOver}
                  >
                    {Language?.general?.start_over}
                  </button>
                  {hidePrev ? null : (
                    <button
                      type="button"
                      className="btn btn-default btnviewprojback text-capitalize mt-3 ml-2"
                      onClick={onPrev}
                    >
                      {Language?.general?.back}
                    </button>
                  )}
                  <input
                    type="submit"
                    className="btn btn-default btnviewprojback text-capitalize mt-3 ml-2"
                    value={Language?.general?.next}
                  />
                </>
              ) : (
                <>
                  <button
                    className="btn btn-default btnSaveandStart text-capitalize mt-3"
                    onClick={saveTourDraft}
                  >
                    {Language?.general?.save_as_draft}
                  </button>
                  <button
                    type="submit"
                    data-toggle="modal"
                    data-target="#paymentInfoModal"
                    className="btn btn-default btnviewproj text-capitalize mt-3 ml-2"
                    disabled={initialData?.status === 'expired' ? true : false}
                  >
                    {location.pathname !== "/profile/tours/add" ? Language?.general.update + ' ' + Language?.general.tour : tourLan?.request_approval}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ToursForm;
